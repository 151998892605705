import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import Many2ManyInput from "../../../../components/Input/SelectInputs/Many2ManyInput";
import FormLabel from "../FormLabel";
import VariableFieldColumn from "../utils/VariableFieldColumn";

interface Many2manyFieldProps {
  field: FormFieldV2;
}

const Many2manyField: FC<Many2manyFieldProps> = ({ field }) => {
  const { domain, value, name, label, disabled, serviceName, variant } = field;
  const { handleChange, startEditing, finishEditing, error } =
    useFormField(field);

  const handleSelect = (values: string[]) => {
    handleChange(values);
  };

  return (
    <VariableFieldColumn variant={variant}>
      <FormLabel>{label}</FormLabel>
      <Many2ManyInput
        serviceName={serviceName!}
        domain={domain}
        value={value}
        inputName={name}
        placeholder={label}
        onSelect={handleSelect}
        onFocus={startEditing}
        onBlur={finishEditing}
        errorMessage={error}
        disabled={!!disabled}
        hideDropdown
        multiValueDisplay="all"
      />
    </VariableFieldColumn>
  );
};

export default Many2manyField;
