/** @jsxImportSource @emotion/react */

import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormSectionV2 } from "../../graphql/generated/types";
import FormSection from "./form.section";
import { sectionHasError } from "./utils/formErrors";
import useForm from "./hooks/useForm.v2";
import FormFooter from "./form.footer";
import Column from "../../components/Grid/Column";
import { FormSummary } from "./components/summaryV2";
import Row from "../../components/Grid/Row";
import Stepper from "./components/stepper/Stepper";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";

interface FormSectionsProps {
  sections: FormSectionV2[];
}

const formStepperHeight = 50;
const formMobileStepperHeight = 80;

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  formStepper: {
    marginBottom: 0,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      padding: "12px 0px",
      height: formMobileStepperHeight,
    },
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      height: formStepperHeight,
      alignContent: "center",
    },
  },
});

const FormSections: FC<FormSectionsProps> = ({ sections }) => {
  const isSingleSection = useMemo(() => sections.length === 1, sections);

  const { setShowFormErrors, handleSubmitFormResponse } = useForm();

  const [currentSectionIndex, setCurrentSectionIndex] = useState(() => {
    for (let i = 0; i < sections.length; i++) {
      if (sectionHasError(sections[i])) {
        return i;
      }
    }
    // If no section has an error, return the first section for single section forms
    // or Summary for forms with multiple sections
    return isSingleSection ? 0 : sections.length;
  });

  const currentSection = useMemo(
    () => sections[currentSectionIndex],
    [currentSectionIndex, sections]
  );

  const handleSelectSession = (index: number) => {
    setCurrentSectionIndex(index);
  };

  const handleNextSession = () => {
    setShowFormErrors(false);
    handleSelectSession(currentSectionIndex + 1);
  };

  const currentSectionHasError = useMemo(() => {
    return sectionHasError(currentSection);
  }, [...(currentSection?.fields || [])]);

  const shouldSubmit = useMemo(() => {
    // If it's a single section form or is in the Summary Step
    return isSingleSection || currentSectionIndex === sections.length;
  }, [isSingleSection, currentSectionIndex]);

  const handleFormSubmit = useCallback(async () => {
    if (currentSectionHasError) {
      setShowFormErrors(true);
    } else if (shouldSubmit) {
      await handleSubmitFormResponse();
    } else {
      handleNextSession();
    }
  }, [
    currentSectionHasError,
    shouldSubmit,
    handleNextSession,
    handleSubmitFormResponse,
    setShowFormErrors,
  ]);

  const submitText = useMemo(() => {
    return currentSection?.submitText || (shouldSubmit ? "Concluir" : "Enviar");
  }, [shouldSubmit, currentSection?.submitText]);

  const steps = useMemo(() => {
    return sections.map(({ title }) => title).concat("Finalizar");
  }, [sections]);

  const [maxStep, setMaxStep] = useState(currentSectionIndex);

  useEffect(() => {
    setMaxStep(Math.max(maxStep, currentSectionIndex));
  }, [currentSectionIndex]);

  const styles = useCSSRulesWithTheme(getCSSRules);

  const handleStepperNavigation = useCallback(
    (index: number) => {
      if (index <= maxStep) {
        setCurrentSectionIndex(index);
      }
    },
    [maxStep]
  );

  return (
    <div>
      {!isSingleSection && (
        <Row noGutters css={styles.formStepper}>
          <Stepper
            steps={steps}
            maxStep={maxStep}
            activeStepIndex={currentSectionIndex}
            handleStepClick={handleStepperNavigation}
          />
        </Row>
      )}
      <Column>
        {currentSection && <FormSection section={currentSection} />}
        {!currentSection && (
          <FormSummary handleEditSection={handleSelectSession} />
        )}
      </Column>
      <Column>
        <FormFooter
          submitText={submitText}
          handleFormSubmit={handleFormSubmit}
          disabled={currentSectionHasError}
        />
      </Column>
    </div>
  );
};

export default FormSections;
