import { FC } from "react";
import useContactDetails from "../../hooks/useContactDetails";
import { Outlet } from "react-router-dom";
import { DetailsRowValueVariants } from "../../components/Details/components/Main/Grid/DetailsRowValue";
import SidebarHeader, { headerHeight } from "../../components/Sidebar/Header";
import { ContactTags } from "../../graphql/generated/types";
import { contactTagLabels } from "../../shared/contacts/contactTags";
import ContactPersonalData from "./ContactDetails/components/ContactPersonalData";

export const ContactDetailsLayout: FC = () => {
  const { contactData } = useContactDetails();
  return (
    <div
      style={{
        padding: "20px",
        boxSizing: "border-box",
        height: `calc(100% - ${headerHeight}px)`,
      }}
    >
      <SidebarHeader
        header={[
          {
            label: "Contato",
            value: {
              text: contactData.name || contactData.mobilePhone || "Detalhes",
              Component: ContactPersonalData,
            },
            variant: DetailsRowValueVariants.Modal,
          },
          {
            label: "Tipo(s)",
            value: (contactData.tags || [])
              .map((t: ContactTags) => contactTagLabels[t])
              .join(", "),
          },
        ]}
      />
      <Outlet />
    </div>
  );
};
