import Table from "../../../../components/Table";
import { Maybe, useListClaimsQuery } from "../../../../graphql/generated/types";
import useContactDetails from "../../../../hooks/useContactDetails";
import { ColorNames } from "../../../../theme";
import { ToDocumentDetailsLocation } from "../../../../Locations";
import ScrollContainer from "../../../../components/ScrollContainer";
import { claimStatusLabels } from "../../../../shared/claims/claimStatus";

type TableClaimsData = {
  _id: string;
  documentId: string;
  externalId: string;
  noticeDate: string;
  status: string;
  events?: Maybe<{ name: string }[]>;
};

const ContactClaims = () => {
  const { contactData } = useContactDetails();

  const { data } = useListClaimsQuery({
    variables: {
      filter: { contactId: contactData._id! },
    },
  });

  const tableClaimsData: TableClaimsData[] = (data?.claims || []).map(
    (claim) => ({
      ...claim,
      status: claimStatusLabels[claim.status],
    })
  );

  return (
    <ScrollContainer>
      <Table<TableClaimsData>
        data={tableClaimsData}
        withBorder
        color={ColorNames.ORANGE}
        rowLink={(claim: TableClaimsData) =>
          ToDocumentDetailsLocation(claim.documentId!)
        }
        columns={[
          {
            label: "ID na Seguradora",
            key: "externalId",
          },
          {
            label: "Data de aviso na Seguradora",
            key: "noticeDate",
            special: "date",
          },
          {
            label: "Eventos",
            key: "events",
            special: "many2many",
          },
          {
            label: "Status",
            key: "status",
          },
        ]}
      />
    </ScrollContainer>
  );
};

export default ContactClaims;
