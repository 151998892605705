/** @jsxImportSource @emotion/react */

import { FC } from "react";
import {
  ConversationsFilterInput,
  Exact,
  GetTwilioConversationsQuery,
  useGetOrCreateContactByPhoneMutation,
  useOnConversationUpdatedSubscription,
  useOnMsgSeenUpdatedSubscription,
} from "../../../graphql/generated/types";
import moment from "moment";
import { theme } from "../../../theme";
import Typography from "../../Typography";
import Row from "../../Grid/Row";
import Column from "../../Grid/Column";
import CustomLink from "../../CustomLink";
import { useNavigate } from "react-router-dom";
import { containsOnlyNumbers, formatPhone } from "../../../utils/formatNumber";
import { useAuth } from "../../../hooks/useAuth";
import { QueryResult } from "@apollo/client";
import { CSSObject } from "@emotion/react";
import { ToContactConversationLocation } from "../../../Locations";
import { playNotificationSound } from "./utils/playNotificationSound";
import BasicSearch from "../../SearchBar/BasicSearch";
import ButtonGroup from "../../ButtonGroup";
import ChatListPaginator from "./ChatListPaginator";
import ScrollContainer from "../../ScrollContainer";

const styles: CSSObject = {
  wrapper: {
    paddingBottom: "10px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    boxSizing: "border-box",
  },
  scrollArea: {
    flex: 1,
    minHeight: 0,
    overflow: "hidden",
  },
  conversationCard: {
    borderTop: `1px solid ${theme.colors.Grey[20]}`,
    width: "100%",
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 4px",
    cursor: "pointer",
    "&:last-of-type": {
      borderBottom: `1px solid ${theme.colors.Grey[20]}`,
    },
    textDecoration: "none",
    color: "inherit",
    position: "relative",
  },
  selectedConversationCard: {
    backgroundColor: theme.colors.Green[20],
  },
  searchBox: {
    padding: "15px 0",
  },
  radioBox: {
    width: "100%",
    paddingTop: "10px",
  },
  createContactButtonRow: {
    width: "100%",
    textAlign: "center",
  },
  createContactButton: {
    padding: "8px",
    "&:hover": {
      color: theme.colors.Blue[60],
    },
  },
  rightColumn: {
    textAlign: "center",
    display: "flex",
    alignItems: "end",
    flexDirection: "column",
    justifyContent: "end",
  },
  notSeenCount: {
    marginTop: 10,
    width: 20,
    height: 20,
    borderRadius: "50%",
    zIndex: 1,
    backgroundColor: theme.colors.Green[100],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    color: theme.colors.White[100],
    fontWeight: "bold",
    marginLeft: 10,
  },
};

const ChatList: FC<{
  selectedContactId?: string;
  setOnlySubscribeds: (value: boolean) => Promise<void>;
  query: QueryResult<
    GetTwilioConversationsQuery,
    Exact<{
      filter: ConversationsFilterInput;
    }>
  >;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  page: number;
  setPage: (value: number) => void;
  totalPages: number;
}> = ({
  selectedContactId,
  query,
  setOnlySubscribeds,
  searchTerm,
  setSearchTerm,
  page,
  setPage,
  totalPages,
}) => {
  const { data, refetch } = query;
  const auth = useAuth();

  useOnConversationUpdatedSubscription({
    onData: ({ data: { data: subData } }) => {
      if (subData?.conversationUpdated) {
        refetch();
        if (subData?.conversationUpdated?.newMsg) {
          playNotificationSound();
        }
      }
    },
  });

  useOnMsgSeenUpdatedSubscription({
    onData: ({ data: { data: subData } }) => {
      if (subData?.msgSeenUpdated?.userId === auth.user._id) {
        refetch();
      }
    },
  });

  const navigate = useNavigate();

  const [getOrCreateContactByPhoneMutation] =
    useGetOrCreateContactByPhoneMutation();

  const getOrCreateContactByPhone = async () => {
    const contact = await getOrCreateContactByPhoneMutation({
      variables: {
        mobilePhone: searchTerm,
      },
    });

    navigate(
      ToContactConversationLocation(
        contact.data!.getOrCreateContactByPhone._id!
      )
    );
  };

  const filteredConversations = data?.getTwilioConversations.records || [];

  const shouldShowCreateButton =
    containsOnlyNumbers(searchTerm) &&
    filteredConversations.length === 0 &&
    [10, 11].includes(searchTerm.length);

  return (
    <div css={styles.wrapper}>
      <div css={styles.radioBox}>
        <ButtonGroup
          defaultActiveIndex={0}
          buttons={[
            { onClick: () => setOnlySubscribeds(false), label: "Todas" },
            { onClick: () => setOnlySubscribeds(true), label: "Seguindo" },
          ]}
        />
      </div>
      <div css={styles.searchBox}>
        <BasicSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
      {shouldShowCreateButton && (
        <div css={styles.createContactButtonRow}>
          <Typography
            variant="link"
            onClick={getOrCreateContactByPhone}
            css={styles.createContactButton}
          >
            Criar '{searchTerm}'
          </Typography>
        </div>
      )}

      {/* 
         4. Wrap the ScrollContainer in a flex:1 area
         so it takes up the remaining space.
       */}
      <div css={styles.scrollArea}>
        <ScrollContainer>
          {filteredConversations.map((conv) => (
            <CustomLink
              to={ToContactConversationLocation(conv.contact._id!)}
              css={[
                styles.conversationCard,
                selectedContactId === conv.contact._id
                  ? styles.selectedConversationCard
                  : undefined,
              ]}
              key={conv.contact._id}
            >
              <Column xs={7} lg={7}>
                <Row noGutters noWrap css={{ margin: "0px" }}>
                  <Typography
                    variant="textMedium"
                    color="Black"
                    weight="bold"
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      padding: "4px",
                    }}
                  >
                    {formatPhone(conv.contact.mobilePhone!)}
                    {conv.contact.name ? ` - ${conv.contact.name}` : ""}
                  </Typography>
                </Row>
                <Row css={{ margin: "0px" }} noGutters noWrap>
                  <Typography
                    variant="textMedium"
                    color="Grey"
                    weight="light"
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      padding: "4px",
                    }}
                  >
                    {conv.lastMsg.content ||
                      (conv.lastMsg.medias
                        ? "Midia enviada"
                        : "Mensagem template enviada")}
                  </Typography>
                </Row>
              </Column>
              <Column xs={5} lg={5} css={styles.rightColumn}>
                <Typography variant="textMedium" color="Green">
                  {moment(conv.lastMsg.sentAt).format("DD/MM/YY HH:mm")}
                </Typography>
                {Boolean(conv.notSeenCount) && (
                  <div css={styles.notSeenCount}>{conv.notSeenCount}</div>
                )}
              </Column>
            </CustomLink>
          ))}
        </ScrollContainer>
      </div>

      <ChatListPaginator
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      />
    </div>
  );
};

export default ChatList;
