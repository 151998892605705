import { useMemo } from "react";
import Tabs from "../../../../../components/Tabs";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import ArchivedProposals from "./ArchivedProposals";
import ProposalDetails from "./ProposalDetails";
import SignedProposal from "./SignedProposal";

export const Proposal = () => {
  const {
    documentData: { proposalResult },
  } = useDocumentDetails();
  const tabs = useMemo(() => {
    return [
      {
        title: "Emitida",
        Component: () => (
          <ProposalDetails proposalResult={proposalResult || undefined} />
        ),
      },
      {
        title: "Assinada",
        Component: SignedProposal,
      },
      {
        title: "Arquivadas",
        Component: ArchivedProposals,
      },
    ];
  }, [proposalResult]);
  return <Tabs tabs={tabs} />;
};
