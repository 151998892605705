import { useMemo } from "react";
import { MenuItem } from "../components/Sidebar";
import { DocumentStages, DocumentStatus } from "../graphql/generated/types";
import { ToDocumentDetailsLocation } from "../Locations";
import useDocumentOrder from "./useDocumentOrder";
import { useFlags } from "./useFlags";

/**
 * Arguments for useDocumentSubmenus
 */
interface UseSubmenusArgs {
  documentId?: string;
  stage?: DocumentStages | null;
  status?: DocumentStatus | null;
}

/**
 * A custom hook to generate a submenus array based on the given stage & status.
 */
export function useDocumentSubmenus({
  documentId,
  stage,
  status,
}: UseSubmenusArgs): MenuItem[] {
  // If documentId is undefined, we can't build submenus.
  // You could return an empty array or handle differently as needed
  const { isFlagEnabled } = useFlags();
  if (!documentId) {
    return [];
  }

  // Get numeric/ordinal values of different stages
  const currentStage = useDocumentOrder(stage!, status!);
  const quotationStage = useDocumentOrder(DocumentStages.Simulation);
  const proposalStage = useDocumentOrder(DocumentStages.Proposal);
  const sentProposalStage = useDocumentOrder(
    DocumentStages.Proposal,
    DocumentStatus.Sent
  );
  const policyStage = useDocumentOrder(DocumentStages.Policy);

  const baseUrl = ToDocumentDetailsLocation(documentId);

  // Use `useMemo` to avoid recomputing the array on every render, if you like
  return useMemo(() => {
    const submenus = [
      {
        title: "Geral",
        location: baseUrl,
      },
      {
        title: "Cotação",
        location: baseUrl + "/cotacao",
        disabled: currentStage < quotationStage,
      },
      {
        title: "Proposta",
        location: baseUrl + "/proposta",
        disabled: currentStage < proposalStage,
      },
      {
        title: "Pagamento",
        location: baseUrl + "/pagamento",
        disabled: currentStage < proposalStage,
      },
      {
        title: "Apólice",
        location: baseUrl + "/apolice",
        disabled: currentStage < sentProposalStage,
      },
      {
        title: "Endosso",
        location: baseUrl + "/endosso",
        disabled: currentStage < policyStage,
      },
      {
        title: "Sinistro",
        location: baseUrl + "/sinistro",
        disabled: currentStage < policyStage,
      },
      {
        title: "Anexos",
        location: baseUrl + "/anexos",
      },
      {
        title: "Notas",
        location: baseUrl + "/notas",
      },
    ];
    if (isFlagEnabled("admin-only")) {
      submenus.push({
        title: "Chat",
        location: baseUrl + "/chat",
      });
    }
    return submenus;
  }, [
    baseUrl,
    currentStage,
    quotationStage,
    proposalStage,
    sentProposalStage,
    policyStage,
  ]);
}
