/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Typography from "../../../../../Typography";
import {
  File,
  Maybe,
  Services,
} from "../../../../../../graphql/generated/types";
import useDownloadFile from "../../../../../../hooks/useDownloadFile";
import Row from "../../../../../Grid/Row";
import Icon from "../../../../../Icons";
import { IconNames } from "../../../../../Icons/styles/iconNames";
import useDetailsColor from "../../../../../../hooks/useDetailsColor";
import { useModalStore } from "../../../../../Modal/context/ModalStore";
import { PdfPreview } from "../../../../../FilePreview/PdfPreview";
import { IconSizes } from "../../../../../Icons/styles/iconSizes";
import { CSSObject } from "@emotion/react";
import LoadingSpinner from "../../../../../Loading/LoadingSpinner";
import { shortenFileName } from "../../../../../../utils/stringUtils";

export interface FileDetailsProps {
  file: Maybe<File> | undefined;
  objectId: Maybe<string> | undefined;
  fieldPath: string;
  serviceName: Services;
}

const styles: CSSObject = {
  iconWrapper: {
    paddingLeft: "5px",
  },
  loadingWrapper: {
    paddingLeft: "10px",
  },
};

const FileDetails: FC<FileDetailsProps> = ({
  file,
  objectId,
  fieldPath,
  serviceName,
}) => {
  const { color } = useDetailsColor();
  const { handleDownloadFile, loading: downloadingFile } = useDownloadFile({
    serviceName,
    objectId: objectId!,
    fieldPath,
  });

  const { openModal } = useModalStore();

  const handlePreview = async () => {
    const result: any = await handleDownloadFile(false);
    openModal(<PdfPreview file={result[0]} />);
  };

  if (!file?.fileName || !objectId)
    return (
      <Typography variant="textMedium" uppercase component="div" align="left">
        -
      </Typography>
    );

  const isPdf = Boolean(
    file.fileName?.endsWith(".pdf") || file.fileName?.endsWith(".PDF")
  );

  return (
    <Row css={{ margin: 0 }} align="center" noGutters>
      <Typography variant="textMedium" uppercase component="div" align="left">
        {shortenFileName(file.fileName, 30)}
      </Typography>
      {downloadingFile && (
        <div css={styles.loadingWrapper}>
          <LoadingSpinner />
        </div>
      )}
      {!downloadingFile && (
        <>
          <Icon
            name={IconNames.Download}
            size={IconSizes.Small}
            color={color}
            tooltipText="Baixar arquivo"
            onClick={() => handleDownloadFile()}
            css={styles.iconWrapper}
          />
          {isPdf && (
            <Icon
              name={IconNames.View}
              size={IconSizes.Small}
              color={color}
              tooltipText="Visualizar arquivo"
              css={styles.iconWrapper}
              onClick={handlePreview}
            />
          )}
        </>
      )}
    </Row>
  );
};

export default FileDetails;
