/** @jsxImportSource @emotion/react */
import {
  CultureListLocation,
  toCultureDetailsLocation,
} from "../../../Locations";
import { Services } from "../../../graphql/generated/types";
import ModalStoreProvider from "../../../components/Modal/context/ModalStore";
import { useFlags } from "../../../hooks/useFlags";
import AppLayout from "../../../components/AppLayout/AppLayout";
import LeftMenu, { MenuItem } from "../../../components/LeftMenu/LeftMenu";
import RightMenu from "../../../components/RightMenu/RightMenu";
import { FC, ReactNode } from "react";
import { useDefaultMenuItems } from "../../../hooks/useDefaultMenuItems";

export function getCultureSubmenus(cultureId: string): MenuItem[] {
  if (!cultureId) {
    return [];
  }
  const baseUrl = toCultureDetailsLocation(cultureId);
  const submenus = [
    {
      title: "Geral",
      location: baseUrl,
    },
    {
      title: "Allianz",
      location: baseUrl + "/allianz",
    },
    { title: "Swiss", location: baseUrl + "/swiss" },
    { title: "Mapfre", location: baseUrl + "/mapfre" },
    { title: "Safras", location: baseUrl + "/safras" },
  ];
  return submenus;
}

export const CulturesWrapper: FC<{
  children: ReactNode;
  selectedCultureId?: string;
  submenus?: MenuItem[];
}> = ({ children, selectedCultureId, submenus }) => {
  const { isFlagEnabled } = useFlags();
  const getMenuItems = () => {
    const menuItens = useDefaultMenuItems(isFlagEnabled("admin-only"));
    if (selectedCultureId) {
      return menuItens.map((item) => {
        if (item.location === CultureListLocation) {
          return {
            ...item,
            submenus: getCultureSubmenus(selectedCultureId),
          };
        }
        return item;
      });
    }
    if (submenus) {
      return menuItens.map((item) => {
        if (item.location === CultureListLocation) {
          return {
            ...item,
            submenus,
          };
        }
        return item;
      });
    }
    return menuItens;
  };

  return (
    <AppLayout
      leftMenu={<LeftMenu menuItems={getMenuItems()} />}
      rightMenu={
        <RightMenu
          docIdRedirectLocation={(cultureId) =>
            toCultureDetailsLocation(cultureId)
          }
          serviceName={Services.Cultures}
        />
      }
    >
      <ModalStoreProvider>{children}</ModalStoreProvider>
    </AppLayout>
  );
};
