/** @jsxImportSource @emotion/react */

import { FC } from "react";
import HR from "../../HorizontalLine";
import DetailsSectionColumns from "../../Details/components/Main/Grid/DetailsSectionColumns";
import { DetailsRowProps } from "../../Details/components/Main/Grid/DetailsRow";
import useIsMobile from "../../../hooks/useIsMobile";
import { ColorNames } from "../../../theme";

interface SidebarHeaderProps {
  header: DetailsRowProps[];
}

export const mobileHeaderHeight = 160;
export const headerHeight = 50;

const SidebarHeader: FC<SidebarHeaderProps> = ({ header }) => {
  const isMobile = useIsMobile();
  return (
    <div
      css={{
        height: isMobile ? mobileHeaderHeight : headerHeight,
      }}
    >
      <HR spacingSize="none" borderWidth={1} color={ColorNames.GREY} />
      <div css={{ marginTop: 8, padding: isMobile ? 0 : "0 24px" }}>
        <DetailsSectionColumns
          noMargins
          columns={header.map((item: DetailsRowProps) => [item])}
        />
      </div>
      <HR spacingSize="none" borderWidth={1} color={ColorNames.GREY} />
    </div>
  );
};

export default SidebarHeader;
