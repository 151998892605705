import { FC } from "react";
import { useParams } from "react-router-dom";
import { PaymentSummary } from "./components/PaymentSummary";
import {
  BasePaymentFieldsFragment,
  useGetPaymentQuery,
} from "../../graphql/generated/types";
import Loading from "../../components/Loading";
import { PaymentsWrapper } from "./PaymentsWrapper";

const PaymentDetails: FC = () => {
  const { paymentId } = useParams();
  const { loading, data, refetch } = useGetPaymentQuery({
    variables: {
      paymentId: paymentId as string,
    },
  });

  if (loading || !data) return <Loading size="extraLarge" />;
  return (
    <PaymentsWrapper selectedPaymentId={paymentId}>
      <PaymentSummary
        payment={data?.payment as BasePaymentFieldsFragment}
        refetch={refetch}
      />
    </PaymentsWrapper>
  );
};

export default PaymentDetails;
