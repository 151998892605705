/** @jsxImportSource @emotion/react */
import { PaymentsLocation, ToPaymentDetailsLocation } from "../../Locations";
import { Services } from "../../graphql/generated/types";
import ModalStoreProvider from "../../components/Modal/context/ModalStore";
import { useFlags } from "../../hooks/useFlags";
import AppLayout from "../../components/AppLayout/AppLayout";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import RightMenu from "../../components/RightMenu/RightMenu";
import { FC, ReactNode } from "react";
import { useDefaultMenuItems } from "../../hooks/useDefaultMenuItems";
import { useUIStore } from "../../hooks/useUIStore";

export const PaymentsWrapper: FC<{
  children: ReactNode;
  selectedPaymentId?: string;
}> = ({ children, selectedPaymentId }) => {
  const { isFlagEnabled } = useFlags();
  const getMenuItems = () => {
    const menuItens = useDefaultMenuItems(isFlagEnabled("admin-only"));
    if (!selectedPaymentId) {
      return menuItens;
    }
    return menuItens.map((item) => {
      if (item.location === PaymentsLocation) {
        return {
          ...item,
          submenus: [
            {
              title: "Geral",
              location: ToPaymentDetailsLocation(selectedPaymentId),
            },
          ],
        };
      }
      return item;
    });
  };

  const { isConfirmationModalOpen, renderConfirmationModal } = useUIStore();

  return (
    <AppLayout
      leftMenu={<LeftMenu menuItems={getMenuItems()} />}
      rightMenu={
        <RightMenu
          docIdRedirectLocation={(paymentId) =>
            ToPaymentDetailsLocation(paymentId)
          }
          serviceName={Services.Payments}
        />
      }
    >
      <ModalStoreProvider>
        {isConfirmationModalOpen && renderConfirmationModal()}
        {children}
      </ModalStoreProvider>
    </AppLayout>
  );
};
