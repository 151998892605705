import {
  ClaimStatus,
  DocumentStages,
  DocumentStatus,
  InsuranceTypeGroupEnum,
  ListDocumentsQuery,
} from "../../../graphql/generated/types";
import { TableColumn } from "../../../hooks/useResizeableTable";
import { NullPartial } from "../../../shared/types";
import { getDocumentPropertyDescription } from "../utils";
import { documentStageLabels } from "../../../shared/documents/documentStage";
import Chip from "../../../components/Chip";
import documentColors from "../../../utils/documentColors";
import { documentStatusLabels } from "../../../shared/documents/documentStatus";
import IconLabel from "../../../components/IconLabel";
import {
  documentStatusIcons,
  insuranceTypeGroupsIcons,
} from "../../../utils/icons";
import { insuranceTypeGroupLabels } from "../../../shared/insurance/insuranceTypeGroup";
import { claimStatusLabels } from "../../../shared/claims/claimStatus";
import { formatDate } from "../../../utils/dateUtils";
import Avatar from "../../../components/Avatar";
import { IconSizes } from "../../../components/Icons/styles/iconSizes";

type DocumentsTableColumns =
  | "id"
  | "farmerName"
  | "farmerCpfCnpj"
  | "stage"
  | "status"
  | "claimStatus"
  | "insuranceTypeGroup"
  | "detail"
  | "area"
  | "premium"
  | "city"
  | "uf"
  | "insuranceCompany"
  | "date"
  | "policyEndDate"
  | "partner"
  | "ope"
  | "com"
  | "sin"
  | "propertyDescription"
  | "harvest";

export interface DocumentsTableData
  extends NullPartial<Record<DocumentsTableColumns, string>> {
  id: string;
}

const documentsTableColumns: TableColumn<DocumentsTableData>[] = [
  { id: "farmerName", label: "PRODUTOR", width: 200, sortKey: "farmer.name" },
  {
    id: "farmerCpfCnpj",
    label: "CPF/CNPJ",
    sortKey: "farmer.cpfCnpj",
  },
  {
    id: "stage",
    label: "ETAPA",
    cell: (value) => {
      const stage = (value as DocumentStages) || null;
      const label = stage ? documentStageLabels[stage].toUpperCase() : "-";
      const color = documentColors[stage];
      return stage ? [<Chip color={color} label={label} />, label] : [];
    },
    width: 120,
  },
  {
    id: "status",
    label: "STATUS",
    cell: (value) => {
      const status = (value as DocumentStatus) || null;
      const label = status ? documentStatusLabels[status] : "-";
      return status
        ? [
            <IconLabel label={label!} icon={documentStatusIcons[status]} />,
            label,
          ]
        : [];
    },
    width: 120,
  },
  {
    id: "insuranceTypeGroup",
    label: "SEGURO",
    cell: (value) => {
      const insuranceType = (value as InsuranceTypeGroupEnum) || null;
      const label = insuranceType
        ? insuranceTypeGroupLabels[insuranceType].toUpperCase()
        : "-";
      return insuranceType
        ? [
            <IconLabel
              label={label!}
              icon={insuranceTypeGroupsIcons[insuranceType]}
            />,
            label,
          ]
        : [];
    },
    width: 140,
  },
  {
    id: "claimStatus",
    label: "SINISTRO",
    cell: (value) => {
      const claimStatus = (value as ClaimStatus) || null;
      const label = claimStatus ? claimStatusLabels[claimStatus] : "-";
      return claimStatus ? [label, label] : [];
    },
  },
  { id: "detail", label: "DETALHE", disableSort: true },
  { id: "area", label: "ÁREA (HA)" },
  {
    id: "premium",
    label: "PRÊMIO (R$)",
    emptyValue: "-",
  },
  { id: "city", label: "CIDADE", sortKey: "property.address.city" },
  { id: "uf", label: "UF", width: 55, sortKey: "property.address.state" },
  { id: "insuranceCompany", label: "SEGURADORA", sortKey: "provider" },
  { id: "date", label: "CRIADO EM", sortKey: "createdAt" },
  { id: "policyEndDate", label: "VIGÊNCIA", sortKey: "policy.endDate" },
  { id: "partner", label: "PARCEIRO", sortKey: "originator.name" },
  {
    id: "ope",
    label: "OPE",
    cell: (value) => [
      <Avatar name={value || ""} size={IconSizes.Large} />,
      value || "-",
    ],
    width: 70,
    disableSort: true,
  },
  {
    id: "com",
    label: "COM",
    cell: (value) => [
      <Avatar name={value || ""} size={IconSizes.Large} />,
      value || "-",
    ],
    width: 70,
    disableSort: true,
  },
  {
    id: "sin",
    label: "SIN",
    cell: (value) => [
      <Avatar name={value || ""} size={IconSizes.Large} />,
      value || "-",
    ],
    width: 70,
    disableSort: true,
  },
  {
    id: "propertyDescription",
    label: "PROPRIEDADE",
    disableSort: true,
  },
  { id: "harvest", label: "SAFRA", sortKey: "harvest.name" },
];

export const mapTableData = (
  documents?: ListDocumentsQuery["documents"]
): DocumentsTableData[] => {
  return (
    documents?.map((doc) => ({
      id: doc._id!,
      farmerName: doc.farmer?.name,
      farmerCpfCnpj: doc.farmer?.cpfCnpj,
      stage: doc.stage,
      status: doc.status,
      claimStatus: doc.claimStatus,
      insuranceTypeGroup: doc.insuranceTypeGroup,
      detail: doc.description,
      area: doc.area?.toString() || undefined,
      premium: doc.premium?.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      city: doc.properties?.[0]?.address?.city,
      uf: doc.properties?.[0]?.address?.state,
      insuranceCompany: doc.provider?.toUpperCase(),
      date: formatDate(doc.createdAt),
      policyEndDate: doc.policy?.endDate
        ? formatDate(doc.policy.endDate)
        : undefined,
      partner: doc.originator?.name?.toUpperCase(),
      ope: doc.operationalResponsible?.name,
      com: doc.commercialResponsible?.name,
      sin: doc.claimResponsible?.name,
      propertyDescription: getDocumentPropertyDescription(doc.properties),
      harvest: doc.crop?.harvest?.name,
    })) || []
  );
};

export default documentsTableColumns;
