/** @jsxImportSource @emotion/react */
import { Contact, Services } from "../../graphql/generated/types";
import ModalStoreProvider from "../../components/Modal/context/ModalStore";
import { useFlags } from "../../hooks/useFlags";
import AppLayout from "../../components/AppLayout/AppLayout";
import LeftMenu, { MenuItem } from "../../components/LeftMenu/LeftMenu";
import RightMenu from "../../components/RightMenu/RightMenu";
import { FC, ReactNode } from "react";
import { ContactsLocation, ToContactDetailsLocation } from "../../Locations";
import { getContactSubmenus } from "./Routes";
import { useUIStore } from "../../hooks/useUIStore";
import { useDefaultMenuItems } from "../../hooks/useDefaultMenuItems";

export const ContactsWrapper: FC<{
  children: ReactNode;
  selectedContact?: Contact;
  submenus?: MenuItem[];
}> = ({ children, selectedContact, submenus }) => {
  const { isFlagEnabled } = useFlags();
  const getMenuItems = () => {
    const menuItens = useDefaultMenuItems(isFlagEnabled("admin-only"));
    if (selectedContact) {
      const baseUrl = ToContactDetailsLocation(selectedContact._id);
      return menuItens.map((item) => {
        if (item.location === ContactsLocation) {
          return {
            ...item,
            submenus: getContactSubmenus(selectedContact).map((submenu) => ({
              ...submenu,
              location: submenu.path ? baseUrl + "/" + submenu.path : baseUrl,
            })),
          };
        }
        return item;
      });
    }
    if (submenus) {
      return menuItens.map((item) => {
        if (item.location === ContactsLocation) {
          return {
            ...item,
            submenus,
          };
        }
        return item;
      });
    }
    return menuItens;
  };

  const {
    isConfirmationModalOpen,
    renderConfirmationModal,
    isSubmittedModalOpen,
    renderSubmittedModal,
  } = useUIStore();
  return (
    <AppLayout
      leftMenu={<LeftMenu menuItems={getMenuItems()} />}
      rightMenu={
        <RightMenu
          docIdRedirectLocation={(contactId) =>
            ToContactDetailsLocation(contactId)
          }
          serviceName={Services.Contacts}
        />
      }
    >
      <ModalStoreProvider>
        {isConfirmationModalOpen && renderConfirmationModal()}
        {isSubmittedModalOpen && renderSubmittedModal()}
        {children}
      </ModalStoreProvider>
    </AppLayout>
  );
};
