import { Routes, Route, Outlet } from "react-router-dom";
import {
  CreateCultureLocation,
  CultureDetailLocation,
  CultureListLocation,
} from "../../Locations";
import { RouteObject } from "../../types";
import { CreateCulture } from "./Create";
import Culture from "./Details";
import { Harvests } from "./Details/components/Harvests";
import Summary from "./Details/components/Summary";
import AllianzSettings from "./Details/settings/Allianz/Table";
import MapfreSettingsTable from "./Details/settings/Mapfre/Table";
import SwissSettingsTable from "./Details/settings/Swiss/Table";
import CulturesList from "./List";

export const CultureDetailsRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          // 20px padding applied to all children routes
          <div
            style={{
              padding: "20px",
              height: "100%",
              boxSizing: "border-box",
            }}
          >
            <Outlet />
          </div>
        }
      >
        <Route index element={<Summary />} />
        <Route path="allianz" element={<AllianzSettings />} />
        <Route path="swiss" element={<SwissSettingsTable />} />
        <Route path="mapfre" element={<MapfreSettingsTable />} />
        <Route path="safras" element={<Harvests />} />
      </Route>
    </Routes>
  );
};

export const cultureRoutes: RouteObject[] = [
  {
    path: CultureListLocation,
    element: <CulturesList />,
    withAuth: true,
    flag: "admin-only",
  },
  {
    path: CultureDetailLocation + "/*",
    element: <Culture />,
    withAuth: true,
    flag: "admin-only",
  },

  // CONSERTAR ESSE!!!
  {
    path: CreateCultureLocation,
    element: <CreateCulture />,
    withAuth: true,
    flag: "admin-only",
  },
];
