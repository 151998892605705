import { FC, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { DetailsRowProps } from "../../../components/Details/components/Main/Grid/DetailsRow";
import { DetailsRowValueVariants } from "../../../components/Details/components/Main/Grid/DetailsRowValue";
import SidebarHeader, {
  headerHeight,
} from "../../../components/Sidebar/Header";
import { InsuranceTypeGroupEnum } from "../../../graphql/generated/types";
import useDocumentDetails from "../../../hooks/useDocumentDetails";
import { getDocumentPropertyDescription } from "../utils";
import BeneficiaryData from "./sections/BeneficiaryData";
import CropData from "./sections/CropData";
import EquipmentsTable from "./sections/Equipments/EquipmentsTable";
import FarmerData from "./sections/FarmerData";
import ForestItemsTable from "./sections/ForestItems/ForestItemsTable";
import LivestockFarmingData from "./sections/Livestock/LivestockFarmingData";
import PropertiesTable from "./sections/Properties/PropertiesTable";
import PropertyImprovementsTable from "./sections/PropertyImprovements/PropertyImprovementsTable";

export const Layout: FC = () => {
  const {
    documentData: {
      farmer,
      beneficiary,
      crop,
      properties,
      description,
      insuranceTypeGroup,
    },
  } = useDocumentDetails();
  const headerInfo = useMemo<DetailsRowProps[]>(() => {
    return [
      {
        label: "Produtor",
        value: { text: farmer!.name!, Component: FarmerData },
        variant: DetailsRowValueVariants.Modal,
      },
      beneficiary && {
        label: "Beneficiário",
        value: { text: beneficiary?.name!, Component: BeneficiaryData },
        variant: DetailsRowValueVariants.Modal,
      },
      crop && {
        label: "Lavoura",
        value: {
          text: `${crop?.cultureName} // ${crop?.area} ha`,
          Component: CropData,
        },
        variant: DetailsRowValueVariants.Modal,
      },
      insuranceTypeGroup === InsuranceTypeGroupEnum.Machinery && {
        label: "Equipamentos",
        value: {
          text: description,
          Component: EquipmentsTable,
        },
        variant: DetailsRowValueVariants.Modal,
      },
      insuranceTypeGroup === InsuranceTypeGroupEnum.Forest && {
        label: "Florestas",
        value: {
          text: description,
          Component: ForestItemsTable,
        },
        variant: DetailsRowValueVariants.Modal,
      },
      insuranceTypeGroup === InsuranceTypeGroupEnum.PropertyImprovement && {
        label: "Benfeitorias",
        value: {
          text: description,
          Component: PropertyImprovementsTable,
        },
        variant: DetailsRowValueVariants.Modal,
      },
      insuranceTypeGroup === InsuranceTypeGroupEnum.Livestock && {
        label: "Rebanho",
        value: {
          text: description,
          Component: LivestockFarmingData,
        },
        variant: DetailsRowValueVariants.Modal,
      },
      {
        label: "Propriedade",
        value: {
          text: getDocumentPropertyDescription(properties),
          Component: PropertiesTable,
        },
        variant: DetailsRowValueVariants.Modal,
      },
    ].filter(Boolean) as DetailsRowProps[];
  }, [crop, beneficiary, description, insuranceTypeGroup, farmer, properties]);

  return (
    <div
      style={{
        height: `calc(100% - ${headerHeight}px)`,
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <SidebarHeader header={headerInfo} />
      <Outlet />
    </div>
  );
};
