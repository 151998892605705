/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ClearIndicatorProps } from "react-select";
import Icon from "../../../Icons";
import { IconNames } from "../../../Icons/styles/iconNames";
import { IconSizes } from "../../../Icons/styles/iconSizes";
import { SelectInputOptionInterface } from "../SelectInput";
import { ColorNames } from "../../../../theme";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";

const getStyles: CSSRulesResolver = (props) => ({
  indicator: {
    position: "relative",
    width: 24,
    padding: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    boxSizing: "border-box",
    "&:after": {
      content: '" "',
      position: "absolute",
      top: 6,
      bottom: 6,
      right: 0,
      width: 1,
      backgroundColor: props.colors.Grey[60],
    },
  },
});

const ClearIndicator: FC<
  ClearIndicatorProps<SelectInputOptionInterface, true>
> = (props) => {
  const styles = useCSSRulesWithTheme(getStyles);

  return (
    <div css={styles.indicator}>
      <Icon
        name={IconNames.Close}
        size={IconSizes.Small}
        color={ColorNames.GREY}
        onClick={props.clearValue}
      />
    </div>
  );
};

export default ClearIndicator;
