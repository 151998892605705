/** @jsxImportSource @emotion/react */
import React from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import Avatar from "../../Avatar";
import Typography from "../../Typography";
import { useAuth } from "../../../hooks/useAuth";
import { ColorNames } from "../../../theme";
import { IconSizes } from "../../Icons/styles/iconSizes";
import HR from "../../HorizontalLine";
import CustomLink from "../../CustomLink";
import { useUIStore } from "../../../hooks/useUIStore";
import { MyOrgLocation, TermsAndConditionsLocation } from "../../../Locations";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";
import { UserRoles } from "../../../graphql/generated/types";
import ModalStoreProvider from "../../Modal/context/ModalStore";

interface UserMenuDropdownProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const getCSSRules: CSSRulesResolver = (props) => ({
  dropdownMenu: {
    position: "fixed",
    top: 12,
    right: 60,
    backgroundColor: `${props.colors.White[100]}`,
    minWidth: 250,
    borderRadius: 5,
    border: `2px solid ${props.colors.Green[100]}`,
    padding: 10,
    zIndex: 3000,
  },
  userName: {
    cursor: "default",
    display: "flex",
    alignItems: "center",
    "& > div": {
      marginRight: 10,
    },
  },
  menuItem: {
    cursor: "pointer",
    padding: "10px 5px",
    display: "flex",
    gap: 6,
    alignItems: "center",
    "& > div": {
      marginRight: 10,
    },
    "&:hover": {
      backgroundColor: `${props.colors.LightGrey[80]}`,
    },
  },
});

const UserMenuDropdown: React.FC<UserMenuDropdownProps> = ({
  open,
  setOpen,
}) => {
  const { user, signOut } = useAuth();
  const styles = useCSSRulesWithTheme(getCSSRules);

  const { openProfileModal } = useUIStore();

  const handleOpenProfileModal = () => {
    openProfileModal();
    setOpen(false);
  };

  return open ? (
    <ModalStoreProvider>
      <div css={styles.dropdownMenu}>
        <div css={styles.userName}>
          <Avatar
            name={user?.name}
            color={ColorNames.GREEN}
            size={IconSizes.ExtraLarge}
            filled
          />
          <div>
            <Typography
              variant="textMedium"
              component="div"
              align="left"
              weight="bold"
            >
              {user?.name}
            </Typography>
            <Typography variant="textSmall" component="div" align="left">
              {user?.email}
            </Typography>
          </div>
        </div>
        <HR color={ColorNames.GREEN} />
        <Typography
          variant="textMedium"
          align="left"
          component="div"
          css={styles.menuItem}
          onClick={handleOpenProfileModal}
        >
          Perfil
        </Typography>
        <CustomLink to={TermsAndConditionsLocation}>
          <Typography
            variant="textMedium"
            align="left"
            component="div"
            css={styles.menuItem}
          >
            Termos e condições
          </Typography>
        </CustomLink>
        <Typography
          variant="textMedium"
          align="left"
          component="div"
          css={styles.menuItem}
          onClick={signOut}
        >
          Logout
          <Icon name={IconNames.Logout} size={IconSizes.Small} />
        </Typography>
        {Boolean(user.orgId && user.role === UserRoles.OrgAdmin) && (
          <CustomLink to={MyOrgLocation}>
            <Typography
              variant="textMedium"
              align="left"
              component="div"
              css={styles.menuItem}
            >
              Minha organização
            </Typography>
          </CustomLink>
        )}
      </div>
    </ModalStoreProvider>
  ) : null;
};

export default UserMenuDropdown;
