import { useState } from "react";
import { IconNames } from "../components/Icons/styles/iconNames";
import {
  ContactsLocation,
  CultureListLocation,
  HomeLocation,
  PaymentsLocation,
} from "../Locations";
import { MenuItem } from "../components/LeftMenu/LeftMenu";

export const useDefaultMenuItems = (isAdmin: boolean) => {
  const [isSupportMaterialOpen, setIsSupportMaterialOpen] = useState(false);
  const items: MenuItem[] = [
    {
      title: "Seguros",
      location: HomeLocation,
      icon: IconNames.Documents,
    },
  ];

  if (isAdmin) {
    items.push(
      {
        title: "Contatos",
        location: ContactsLocation,
        icon: IconNames.Organization,
      },
      {
        title: "Culturas",
        location: CultureListLocation,
        icon: IconNames.Agriculture,
      },
      {
        title: "Pagamentos",
        location: PaymentsLocation,
        icon: IconNames.Money,
      }
    );
  }
  const submenus = [
    {
      title: "Material Prisma",
      location: process.env.REACT_APP_SUPPORT_MATERIAL_URL,
      newTab: true,
    },
    {
      title: "Mapa/ZARC",
      location:
        "https://mapa-indicadores.agricultura.gov.br/publico/extensions/Zarc/Zarc.html",
      newTab: true,
    },
    {
      title: "Histórico de Culturas",
      location:
        "https://cidades.ibge.gov.br/brasil/pesquisa/14/10193?tipo=grafico",
      newTab: true,
    },
    {
      title: "MAPA - Portarias",
      location:
        "https://www.gov.br/agricultura/pt-br/assuntos/riscos-seguro/programa-nacional-de-zoneamento-agricola-de-risco-climatico/portarias/",
      newTab: true,
    },
  ];
  items.push({
    title: "Material de Apoio",
    icon: IconNames.Scrutiny,
    submenus: isSupportMaterialOpen ? submenus : [],
    onClick: () => setIsSupportMaterialOpen(!isSupportMaterialOpen),
  });

  return items;
};
