import moment from "moment";
import "moment/locale/pt-br";

export const isToday = (date: Date) => {
  const today = moment();
  return moment(date).isSame(today, "day");
};

export const formatISODate = (date?: string | Date) => {
  if (!date) return "";
  return moment.utc(date).format("DD/MM/YYYY");
};

export const formatDate = (date?: Date | null) => {
  if (!date) return;
  const formated = moment(date).format("DD/MM/YYYY");
  return formated;
};

export const formatDateWithTime = (date?: Date) => {
  if (!date) return "-";
  return moment(date).format("DD/MM/YYYY HH:mm");
};
