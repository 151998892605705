import {
  FileDetailsFragment,
  Services,
  Livestock,
  InsuranceDocument,
  ForestItem,
  Crop,
  Claim,
  PaymentStatus,
  Policy,
  Result,
  Contact,
} from "../../../../../graphql/generated/types";

export type FileField = {
  label: string;
  value:
    | {
        file: FileDetailsFragment;
        objectId: string;
        fieldPath: string;
        serviceName: Services;
      }
    | false;
};

export type Section = {
  fields: FileField[];
  title: string;
  subsections?: Section[];
};

const getLivestockFields = (livestock: Livestock): FileField[] => {
  const questionaryFile = {
    label: "Questionário do rebanho",
    value: !!livestock.questionaryFile && {
      file: {
        fileName: livestock.questionaryFile.fileName,
      },
      objectId: livestock._id,
      fieldPath: "questionaryFile",
      serviceName: Services.LivestockFarmings,
    },
  };
  const detailsFiles = livestock.animals.map((animal, i) => ({
    label: `Detalhes do animal ${i + 1}`,
    value: !!animal.detailsFile && {
      file: {
        fileName: animal.detailsFile.fileName,
      },
      objectId: animal._id,
      fieldPath: "detailsFile",
      serviceName: Services.LivestockAnimals,
    },
  }));
  return [...detailsFiles, questionaryFile];
};

export const getGeneralFields = (
  doc: InsuranceDocument
): FileField[] | null => {
  if (doc.crop) {
    return getCropFields(doc.crop);
  }
  if (doc.livestock) {
    return getLivestockFields(doc.livestock);
  }
  if (doc.forestItems) {
    return getForestFields(doc.forestItems);
  }
  return null;
};

const getForestFields = (forestItems: ForestItem[]): FileField[] => {
  const forestItemFiles = forestItems.map((forestItem, i) => ({
    label: `KML - Item florestal ${i + 1}`,
    value: !!forestItem.kmlFile && {
      file: {
        fileName: forestItem.kmlFile?.fileName,
      },
      objectId: forestItem._id,
      fieldPath: "file",
      serviceName: Services.ForestItems,
    },
  }));
  return forestItemFiles;
};

const getCropFields = (crop: Crop): FileField[] => {
  const cropKmlFile = {
    label: "KML da lavoura",
    value: !!(crop?.kmlFile || crop?.plots?.length! > 0) && {
      file: {
        fileName: crop?.kmlFile ? crop.kmlFile.fileName : "Gerar Arquivo",
      },
      objectId: crop._id!,
      fieldPath: "kmlFile",
      serviceName: Services.Crops,
    },
  };

  return [cropKmlFile];
};

export const getClaimSpecificFields = (claim: Claim): FileField[] => {
  const reportFile = {
    label: "Carta de conclusão",
    value: !!claim.reportFile && {
      file: {
        fileName: claim.reportFile.fileName,
      },
      objectId: claim._id,
      fieldPath: "reportFile",
      serviceName: Services.Claims,
    },
  };
  const contestationLetterFile = {
    label: "Carta de contestação",
    value: !!claim.contestationLetterFile && {
      file: {
        fileName: claim.contestationLetterFile.fileName,
      },
      objectId: claim._id,
      fieldPath: "contestationLetterFile",
      serviceName: Services.Claims,
    },
  };
  const contestationReportFile = {
    label: "Carta de conclusão da contestação",
    value: !!claim.contestationReportFile && {
      file: {
        fileName: claim.contestationReportFile.fileName,
      },
      objectId: claim._id,
      fieldPath: "contestationReportFile",
      serviceName: Services.Claims,
    },
  };
  return [reportFile, contestationLetterFile, contestationReportFile];
};

export const getClaimFields = (doc: InsuranceDocument) => {
  const costNFFiles = {
    label: "Notas Fiscais",
    value: !!doc.costNFFiles?.length && {
      file: {
        fileName: "Notas Fiscais.zip",
      },
      objectId: doc._id!,
      fieldPath: "costNFFiles",
      serviceName: Services.Documents,
    },
  };

  const reportFiles = (doc.inspections || []).map((inspection, i) => ({
    label: `Laudo ${i + 1}`,
    value: !!inspection.reportFile && {
      file: {
        fileName: inspection.reportFile.fileName,
      },
      objectId: inspection._id,
      fieldPath: "reportFile",
      serviceName: Services.Inspections,
    },
  }));

  let fields: FileField[] = [costNFFiles, ...reportFiles];
  if (doc.crop) {
    const harvestStartDateSignedLetterFile = {
      label: "Documento de início de colheita assinado",
      value: !!doc.crop.harvestStartDateSignedLetterFile && {
        file: {
          fileName: doc.crop.harvestStartDateSignedLetterFile.fileName,
        },
        objectId: doc.crop._id!,
        fieldPath: "harvestStartDateSignedLetterFile",
        serviceName: Services.Crops,
      },
    };
    const harvestStartDateLetterFile = {
      label: "Documento de início de colheita",
      value: !!doc.crop.harvestStartDateLetterFile && {
        file: {
          fileName: doc.crop.harvestStartDateLetterFile.fileName,
        },
        objectId: doc.crop._id!,
        fieldPath: "harvestStartDateLetterFile",
        serviceName: Services.Crops,
      },
    };
    const harvestEndDateLetterFile = {
      label: "Documento de fim de colheita",
      value: !!doc.crop.harvestEndDateLetterFile && {
        file: {
          fileName: doc.crop.harvestEndDateLetterFile.fileName,
        },
        objectId: doc.crop._id!,
        fieldPath: "harvestEndDateLetterFile",
        serviceName: Services.Crops,
      },
    };
    const harvestEndDateSignedLetterFile = {
      label: "Documento de fim de colheita assinado",
      value: !!doc.crop.harvestEndDateSignedLetterFile && {
        file: {
          fileName: doc.crop.harvestEndDateSignedLetterFile.fileName,
        },
        objectId: doc.crop._id!,
        fieldPath: "harvestEndDateSignedLetterFile",
        serviceName: Services.Crops,
      },
    };
    fields = [
      ...fields,
      harvestStartDateLetterFile,
      harvestStartDateSignedLetterFile,
      harvestEndDateLetterFile,
      harvestEndDateSignedLetterFile,
    ];
  }

  return fields;
};

export const getPaymentsFields = (doc: InsuranceDocument) => {
  const billFiles = [...(doc.payments || [])]
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((payment) => payment.status !== PaymentStatus.Cancelled)
    .map((payment) => ({
      label: payment.name,
      value: !!payment.billFile && {
        file: {
          fileName: payment.billFile.fileName,
        },
        objectId: payment._id,
        fieldPath: "billFile",
        serviceName: Services.Payments,
      },
    }));
  return billFiles;
};

export const getPolicyFields = (policy: Policy) => {
  const policyFile = {
    label: "Apólice",
    value: {
      file: {
        fileName: policy.policyFile.fileName,
      },
      objectId: policy._id,
      fieldPath: "policyFile",
      serviceName: Services.Policies,
    },
  };
  const billsFile = {
    label: "Boletos",
    value: !!policy.billsFile && {
      file: {
        fileName: policy.billsFile.fileName,
      },
      objectId: policy._id,
      fieldPath: "billsFile",
      serviceName: Services.Policies,
    },
  };
  return [policyFile, billsFile];
};

export const getProposalFields = (proposal: Result): FileField[] => {
  const proposalFile = {
    label: "Proposta",
    value: {
      file: {
        fileName: proposal.proposalFile?.fileName,
      },
      objectId: proposal._id!,
      fieldPath: "proposalFile",
      serviceName: Services.Results,
    },
  };
  const signedProposalFile = {
    label: "Proposta assinada",
    value: !!proposal.signedProposalFile && {
      file: {
        fileName: proposal.signedProposalFile.fileName,
      },
      objectId: proposal._id!,
      fieldPath: "signedProposalFile",
      serviceName: Services.Results,
    },
  };
  const proposalSketchMap = {
    label: "Croqui da área",
    value: !!proposal.proposalSketchMap && {
      file: {
        fileName: proposal.proposalSketchMap.fileName,
      },
      objectId: proposal._id!,
      fieldPath: "proposalSketchMap",
      serviceName: Services.Results,
    },
  };
  return [proposalFile, signedProposalFile, proposalSketchMap];
};

export const getContactFields = (contact: Contact): FileField[] => {
  const documentFile = {
    label: "Documento",
    value: !!contact.documentFile && {
      file: {
        fileName: contact.documentFile.fileName,
      },
      objectId: contact._id,
      fieldPath: "documentFile",
      serviceName: Services.Contacts,
    },
  };
  const addressProofFile = {
    label: "Comprovante de endereço",
    value: !!contact.addressProofFile && {
      file: {
        fileName: contact.addressProofFile.fileName,
      },
      objectId: contact._id,
      fieldPath: "addressProofFile",
      serviceName: Services.Contacts,
    },
  };
  return [documentFile, addressProofFile];
};
