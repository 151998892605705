/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { theme } from "../../theme";
import UserProfileModal from "../Modal/userProfile";
import { useAuth } from "../../hooks/useAuth";
import { useUIStore } from "../../hooks/useUIStore";
import useOutsideClick from "../../hooks/useOutsideClick";

interface AppLayoutProps extends PropsWithChildren {
  leftMenu: React.ReactNode;
  rightMenu: React.ReactNode;
}

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.colors.LightGrey[60],
  },
  content: {
    width: "100%",
    backgroundColor: theme.colors.White[100],
    margin: "12px 0",
    borderRadius: 30,
    overflow: "hidden",
    padding: "0 12px",
    paddingTop: 30,
  },
});

const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  leftMenu,
  rightMenu,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { isProfileModalOpen, closeProfileModal } = useUIStore();

  const { isLoggedIn } = useAuth();

  const ref = useOutsideClick(() => {
    if (isProfileModalOpen) {
      closeProfileModal();
    }
  });
  return (
    <div css={styles.container}>
      <div css={styles.leftMenu}>{leftMenu}</div>
      <div css={styles.content}>{children}</div>
      <div css={styles.rightMenu}>{rightMenu}</div>
      {isLoggedIn && (
        <UserProfileModal
          onRef={ref}
          show={isProfileModalOpen}
          onClose={closeProfileModal}
        />
      )}
    </div>
  );
};

export default AppLayout;
