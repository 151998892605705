import React, { FC, ReactNode, useCallback, useEffect, useMemo } from "react";
import PageTitle from "../components/PageTitle";
import {
  Contact,
  RatingScale,
  useAddCourseToUserMutation,
  useDisableUserMutation,
  useEnableUserMutation,
  useHandleSignupApprovalMutation,
  useSetContactCommentsMutation,
  useSetContactCommissionMutation,
  useSetContactCulturesMutation,
  useSetContactRatingMutation,
  useSetContactResponsibleMutation,
  useUpgradeUserToAdminMutation,
  useSetContactOriginatorMutation,
  useSetContactAgronomistResponsibleMutation,
} from "../graphql/generated/types";
import { useErrorHandler } from "../hooks/useErrorHandler";
import { useNavigate } from "react-router-dom";
import { useUIStore } from "../hooks/useUIStore";
import useContactColor from "../hooks/useContactColor";
import { ToConsultantRejectionLocation } from "../Locations";
import useConfirmedAction from "../hooks/useConfirmedAction";
import { toast } from "react-toastify";

interface ContactDetailsContextValue {
  refetch: () => Promise<any>;
  contactData: Contact;
  loading?: boolean;
  handleSetResponsible: (responsibleId?: string) => void;
  handleSetAgronomistResponsible: (responsibleId?: string) => void;
  handleAddRating: (rating: RatingScale) => void;
  handleAddComment: (comment: string) => void;
  handleApprovePartner: () => void;
  handleRejectPartner: () => void;
  handleDisablePartner: () => void;
  handleEnablePartner: () => void;
  handleAddCultureToPartner: (culturesId: string[]) => void;
  handleAddCourse: (finishedCourse: boolean) => void;
  handleSetCommission: (commission: number) => void;
  handleUpgradeToAdmin: () => void;
  handleSetOriginator: (originatorId?: string) => void;
}
export const ContactDetailsContext =
  React.createContext<ContactDetailsContextValue>({
    refetch: async () => {},
    handleSetResponsible: () => null,
    handleSetAgronomistResponsible: () => null,
    handleAddRating: () => null,
    handleAddComment: () => null,
    handleApprovePartner: () => null,
    handleRejectPartner: () => null,
    handleDisablePartner: () => null,
    handleEnablePartner: () => null,
    handleAddCultureToPartner: () => null,
    handleAddCourse: () => null,
    contactData: {
      _id: "",
    },
    handleSetCommission: () => null,
    handleUpgradeToAdmin: () => null,
    handleSetOriginator: () => null,
  });

interface ContactDetailsProviderProps {
  refetch: () => Promise<any>;
  contactData: Contact;
  children: ReactNode;
}

const ContactDetailsProvider: FC<ContactDetailsProviderProps> = ({
  children,
  contactData: contact,
  refetch,
}) => {
  const { withErrorHandler } = useErrorHandler();
  const navigate = useNavigate();

  const user = useMemo(() => {
    return contact?.user;
  }, [contact?.user]);

  const { setDocumentColor } = useUIStore();
  const roleColor = useContactColor(user?.role!);

  useEffect(() => {
    setDocumentColor(roleColor);
  }, [roleColor]);

  const setRating = useSetContactRatingMutation()[0];
  const setComments = useSetContactCommentsMutation()[0];
  const setCultures = useSetContactCulturesMutation()[0];
  const setResponsible = useSetContactResponsibleMutation()[0];
  const setAgronomistResponsible =
    useSetContactAgronomistResponsibleMutation()[0];
  const addCourseToUserMutation = useAddCourseToUserMutation()[0];
  const setCommission = useSetContactCommissionMutation()[0];

  const approveSignup = useHandleSignupApprovalMutation()[0];
  const enableUser = useEnableUserMutation()[0];
  const disableUser = useDisableUserMutation()[0];
  const upgradeUserToAdmin = useUpgradeUserToAdminMutation()[0];
  const setOriginator = useSetContactOriginatorMutation()[0];

  const handleSetResponsible = useCallback(
    withErrorHandler(async (responsibleId?: string) => {
      await setResponsible({
        variables: { contactId: contact._id!, responsibleId },
      });
      refetch();
    }, "Erro ao atribuir responsável"),
    [contact._id, setResponsible, refetch]
  );

  const handleSetAgronomistResponsible = useCallback(
    withErrorHandler(async (responsibleId?: string) => {
      await setAgronomistResponsible({
        variables: { contactId: contact._id!, responsibleId },
      });
      refetch();
    }, "Erro ao atribuir agrônomo responsável"),
    [contact._id, setResponsible, refetch]
  );

  handleSetAgronomistResponsible;

  const handleAddRating = useCallback(
    withErrorHandler(async (rating: RatingScale) => {
      await setRating({
        variables: { contactId: contact!._id!, rating },
      });
      await refetch();
    }, "Erro ao atribuir rating"),
    [contact._id, setRating, refetch]
  );
  const handleAddCourse = useCallback(
    withErrorHandler(async (finishedCourse: boolean) => {
      await addCourseToUserMutation({
        variables: { userId: user!._id!, finishedCourse },
      });
      await refetch();
    }, "Erro ao atribuir curso"),
    [user?._id, addCourseToUserMutation, refetch]
  );

  const handleAddComment = useCallback(
    withErrorHandler(async (comments: string) => {
      await setComments({
        variables: { contactId: contact._id!, comments },
      });
      await refetch();
    }, "Erro ao atribuir comentário"),
    [contact._id, setComments]
  );

  const approvePartner = useCallback(async () => {
    await withErrorHandler(async () => {
      await approveSignup({
        variables: { contactId: contact._id! },
      });
      await refetch();
      await toast.success("Contato aprovado com sucesso");
    }, "Não foi possível realizar a aprovação")();
  }, [contact, approveSignup, refetch]);

  const handleApprovePartner = useConfirmedAction(
    "Tem certeza que deseja aprovar esse contato?",
    { onConfirm: [{ onClick: approvePartner }] }
  );

  const handleRejectPartner = useConfirmedAction(
    "Tem certeza que deseja recusar esse contato?",
    {
      onConfirm: [
        {
          onClick: async () => {
            navigate(ToConsultantRejectionLocation(contact!._id!));
          },
        },
      ],
    }
  );

  const upgradeToAdmin = useCallback(
    withErrorHandler(async () => {
      await upgradeUserToAdmin({ variables: { userId: user?._id! } });
      await refetch();
    }, "Não foi possível atualizar o usuário para administrador"),
    [user?._id]
  );

  const handleUpgradeToAdmin = useConfirmedAction(
    "Tem certeza que deseja promover esse usuário a administrador?",
    { onConfirm: [{ onClick: upgradeToAdmin }] }
  );

  const disablePartner = useCallback(
    withErrorHandler(async () => {
      await disableUser({ variables: { userId: user?._id! } });
      await refetch();
    }, "Não foi possível desabilitar esse contato"),
    [user?._id]
  );

  const handleDisablePartner = useConfirmedAction(
    "Tem certeza que deseja desabilitar esse contato?",
    { onConfirm: [{ onClick: disablePartner }] }
  );

  const enablePartner = useCallback(
    withErrorHandler(async () => {
      await enableUser({ variables: { userId: user?._id! } });
      await refetch();
    }, "Não foi possível reabilitar esse contato"),
    [user?._id]
  );

  const handleEnablePartner = useConfirmedAction(
    "Tem certeza que deseja reabilitar esse contato?",
    { onConfirm: [{ onClick: enablePartner }] }
  );

  const handleAddCultureToPartner = useCallback(
    withErrorHandler(async (cultureIds: string[]) => {
      await setCultures({
        variables: { contactId: contact._id!, cultureIds },
      });
      await refetch();
    }, "Erro ao atribuir cultura"),
    [contact._id, setCultures, refetch]
  );

  const handleSetCommission = useCallback(
    withErrorHandler(async (commission: number) => {
      await setCommission({
        variables: { contactId: contact._id!, commission },
      });
      await refetch();
    }, "Erro ao definir comissão"),
    [contact._id, setCommission]
  );

  const handleSetOriginator = useCallback(
    withErrorHandler(async (originatorId?: string) => {
      await setOriginator({
        variables: { contactId: contact._id!, originatorId },
      });
      await refetch();
    }, "Erro ao atribuir parceiro"),
    [contact._id, setOriginator, refetch]
  );

  return (
    <>
      {contact.name && <PageTitle title={`Contato - ${contact.name}`} />}
      <ContactDetailsContext.Provider
        value={{
          contactData: contact,
          refetch,
          handleSetResponsible,
          handleAddRating,
          handleAddComment,
          handleApprovePartner,
          handleRejectPartner,
          handleDisablePartner,
          handleEnablePartner,
          handleAddCultureToPartner,
          handleAddCourse,
          handleSetCommission,
          handleUpgradeToAdmin,
          handleSetOriginator,
          handleSetAgronomistResponsible,
        }}
      >
        {children}
      </ContactDetailsContext.Provider>
    </>
  );
};

export default ContactDetailsProvider;
