/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { CSSObject } from "@emotion/react";
import { ColorNames, theme } from "../../../../../theme";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import { IconSizes } from "../../../../../components/Icons/styles/iconSizes";
import { toast } from "react-toastify";
import Typography from "../../../../../components/Typography";
import Button from "../../../../../components/Button";
import Icon from "../../../../../components/Icons";
import {
  Services,
  useArchiveDocumentOtherFileMutation,
  useGetCreateDocumentOtherFileFormResponseLazyQuery,
  UserRoles,
} from "../../../../../graphql/generated/types";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import { useUIStore } from "../../../../../hooks/useUIStore";
import { useModalStore } from "../../../../../components/Modal/context/ModalStore";
import FormV2 from "../../../../../modules/Forms/form.v2";
import { formatDateWithTime } from "../../../../../utils/dateUtils";
import FileDetails from "../../../../../components/Details/components/Main/Grid/valueVariants/File";
import { useAuth } from "../../../../../hooks/useAuth";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
    borderRadius: theme.borderRadius.lg,
    border: `1px solid ${theme.colors.Grey[20]}`,
    boxShadow: theme.boxShadow.default,
    padding: "12px 24px 24px 24px",
    boxSizing: "border-box",
    marginTop: 24,
    marginBottom: 24,
  },
  otherAttachmentsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: 12,
    maxWidth: "100%",
  },

  // Keep as-is
  fileInput: {
    border: `1px solid ${theme.colors.Grey[40]}`,
    borderRadius: theme.borderRadius.lg,
    padding: 8,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    gap: 4,
    width: "fit-content",
  },

  // Card layout
  fileCard: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    borderRadius: theme.borderRadius.md,
    border: `1px solid ${theme.colors.Grey[20]}`,
    boxShadow: theme.boxShadow.default,
    padding: 16,
    boxSizing: "border-box",
    backgroundColor: theme.colors.White[100],
    minWidth: 320,
  },
  cardRow: {
    display: "flex",
    alignItems: "flex-start",
    gap: 8,
  },
  label: {
    fontWeight: "bold",
    minWidth: 80,
    fontSize: 13,
    color: theme.colors.Black[100],
  },

  // Ensures the description text wraps at ~300px
  descriptionText: {
    maxWidth: 320,
    wordBreak: "break-word",
    whiteSpace: "normal",
    textAlign: "left",
  },

  buttonWrapper: {
    marginTop: 12,
  },
};

export const OtherAttachments: FC<{}> = () => {
  const { documentData, refetch } = useDocumentDetails();
  const { openConfirmationModal } = useUIStore();
  const { closeModal, openModal } = useModalStore();

  const [getCreateOtherFileFormResponse] =
    useGetCreateDocumentOtherFileFormResponseLazyQuery();

  const openCreateOtherFileModal = async () => {
    const response = await getCreateOtherFileFormResponse({
      variables: { docId: documentData._id },
      fetchPolicy: "network-only",
    });
    openModal(
      <FormV2
        formResponseId={
          response.data?.getCreateDocumentOtherFileFormResponse.formResponseId!
        }
        formId="documentOtherFile"
        onSubmit={() => {
          toast.success("Arquivo criado com sucesso!");
          refetch();
          closeModal();
        }}
      />,
      undefined,
      {
        styles: {
          container: {
            height: "auto",
            width: 500,
            top: 200,
            left: 0,
            right: 0,
            margin: "auto",
            borderRadius: theme.borderRadius.xl,
          },
          content: {
            overflow: "visible",
          },
        },
      }
    );
  };

  const [archiveOtherFile] = useArchiveDocumentOtherFileMutation();

  const handleDeleteFile = async (otherFileId: string) => {
    // TODO: remove file from server, then:
    await archiveOtherFile({
      variables: { docId: documentData._id, fileId: otherFileId },
    });
    await refetch();
    toast.success("Arquivo deletado com sucesso!");
  };

  const { user } = useAuth();

  return (
    <div css={styles.wrapper}>
      <Typography weight="bold" variant="h2" margin="xs">
        Outros anexos
      </Typography>

      <div css={styles.otherAttachmentsWrapper}>
        {(documentData.otherFiles || []).map((file, index) => {
          const shouldShowDeleteButton =
            file.createdById === user?.contactId ||
            user.role === UserRoles.Admin;
          return (
            <div key={index} css={styles.fileCard}>
              {/* Description row */}
              <div css={styles.cardRow}>
                <Typography css={styles.label}>Descrição:</Typography>
                <Typography css={styles.descriptionText}>
                  {file.description}
                </Typography>
              </div>
              {/* Creation row */}
              <div css={styles.cardRow}>
                <Typography css={styles.label}>Criado em:</Typography>
                <Typography>{formatDateWithTime(file.createdAt)}</Typography>
              </div>
              <div css={styles.cardRow}>
                <Typography css={styles.label}>Criado por:</Typography>
                <Typography>{file.creatorName}</Typography>
              </div>
              {/* File and actions */}
              <div css={styles.fileInput}>
                <FileDetails
                  key={index}
                  serviceName={Services.Documents}
                  objectId={documentData._id}
                  fieldPath={`otherFiles.${index}.file`}
                  file={file.file}
                />
                {shouldShowDeleteButton && (
                  <Icon
                    name={IconNames.Delete}
                    size={IconSizes.Small}
                    color={ColorNames.GREEN}
                    tooltipText="Deletar"
                    onClick={() => {
                      openConfirmationModal(
                        "Tem certeza que deseja deletar o arquivo?",
                        {
                          onConfirm: [
                            { onClick: () => handleDeleteFile(file._id!) },
                          ],
                        }
                      );
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div css={styles.buttonWrapper}>
        <Button
          text="Novo arquivo"
          style="action"
          onClick={openCreateOtherFileModal}
          iconBefore
          icon={
            <Icon
              name={IconNames.Add}
              size={IconSizes.Small}
              color={ColorNames.GREEN}
            />
          }
        />
      </div>
    </div>
  );
};
