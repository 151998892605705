import CardList from "../CardList";
import { CardFC } from "../CardList/components/Card";
import ResizableTable from "../ResizableTable";
import { useTableContext } from "../../contexts/table";
import { Metrics } from "../Metrics";
import { documentsTableId } from "../../views/Documents";
import BottomListMenu from "../BottomListMenu";
import { TModelFilter } from "../../hooks/useModelFilter";
import Content from "../Content/Content";

interface ListViewProps<C, D> {
  loading: boolean;
  CardComponent: CardFC<D>;
  redirectLocation: (id: string) => string;
  mapTableData: (data?: D[]) => C[];
  total: number;
  uploadModal?: React.ReactNode;
  header?: React.ReactNode;
  filter?: TModelFilter;
}

const ListView = <C extends { id: string }, D>({
  loading,
  redirectLocation,
  CardComponent,
  total,
  mapTableData,
  filter,
  header,
}: ListViewProps<C, D>) => {
  const { actions, data, tableId } = useTableContext();
  return (
    <Content
      header={header}
      footer={
        <BottomListMenu
          shouldHidePagination={actions.activeView === "metrics"}
          total={total}
        />
      }
    >
      {actions.activeView === "card" && (
        <CardList
          data={data as D & { _id: string }[]}
          redirectLocation={redirectLocation}
          CardComponent={CardComponent}
          loading={loading}
        />
      )}

      {actions.activeView === "table" && (
        <ResizableTable
          loading={loading}
          tableData={mapTableData(data)}
          redirectLocation={redirectLocation}
        />
      )}

      {actions.activeView === "metrics" && tableId === documentsTableId && (
        <Metrics filter={filter!} />
      )}
    </Content>
  );
};

export default ListView;
