import { FC } from "react";
import DetailsSectionColumns from "../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { getPaymentDetailsColumns } from "../paymentUtils";
import CustomLink from "../../../components/CustomLink";
import {
  ToContactDetailsLocation,
  ToDocumentDetailsLocation,
} from "../../../Locations";
import {
  GetEditPaymentOnDocumentFormResponseDocument,
  BasePaymentFieldsFragment,
  PaymentStatus,
  useSendPaymentsBillsMutation,
  useSetPaymentDoneMutation,
} from "../../../graphql/generated/types";
import Typography from "../../../components/Typography";
import EditableSection from "../../../components/EditableSection";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import { toast } from "react-toastify";
import useConfirmedAction from "../../../hooks/useConfirmedAction";

export const PaymentSummary: FC<{
  payment: BasePaymentFieldsFragment;
  refetch: () => void;
}> = ({ payment, refetch }) => {
  const columns = getPaymentDetailsColumns(payment);
  const { withErrorHandler } = useErrorHandler();

  const leftSideColumn = [
    ...columns.leftSideColumn,
    {
      label: "Documento",
      value: (
        <CustomLink to={ToDocumentDetailsLocation(payment.document!._id)}>
          <Typography
            variant="link"
            uppercase
            component="div"
            align="left"
            noWrap
          >
            {payment.document?.description}
          </Typography>
        </CustomLink>
      ),
    },
    {
      label: "Responsável",
      value: (
        <CustomLink to={ToContactDetailsLocation(payment.responsible!._id!)}>
          <Typography
            variant="link"
            uppercase
            component="div"
            align="left"
            noWrap
          >
            {payment.responsible?.name}
          </Typography>
        </CustomLink>
      ),
    },
  ];

  const [setPaymentDone] = useSetPaymentDoneMutation();

  const handleSetPaymentDone = withErrorHandler(async () => {
    await setPaymentDone({
      variables: {
        paymentId: payment._id,
      },
    });
    refetch();
    toast.success("Baixa dada no pagamento com sucesso");
  }, "Não foi possível dar baixa");

  const [sendPaymentsBillsMutation] = useSendPaymentsBillsMutation();

  const sendPaymentBill = withErrorHandler(async (silent: boolean = false) => {
    await sendPaymentsBillsMutation({
      variables: {
        paymentIds: [payment._id],
        silent,
      },
    });
    await refetch();
    await toast.success("Boleto enviado com sucesso");
  }, "Não foi possível enviar o boleto");

  const handleSendPaymentBill = useConfirmedAction(
    "Tem certeza que deseja enviar o boleto?",
    {
      onConfirm: [
        {
          text: "Sim",
          onClick: sendPaymentBill,
        },
        {
          text: "Sim, sem notificar",
          onClick: () => sendPaymentBill(true),
        },
      ],
    }
  );

  return (
    <EditableSection
      title="Geral"
      main
      editQuery={GetEditPaymentOnDocumentFormResponseDocument}
      editFlag="admin-only"
      objectId={payment._id}
      onSubmit={() => {
        refetch();
        toast.success("Pagamento editado com sucesso");
      }}
      footerButtons={[
        {
          text: "Dar baixa",
          disabled: payment.status !== PaymentStatus.Pending,
          onClick: handleSetPaymentDone,
        },
        {
          text: "Enviar boleto",
          disabled:
            payment.status !== PaymentStatus.Pending || !payment.billFile,
          onClick: handleSendPaymentBill,
        },
      ]}
    >
      <DetailsSectionColumns
        columns={[leftSideColumn, columns.rightSideColumn]}
      />
    </EditableSection>
  );
};
