/** @jsxImportSource @emotion/react */
import React from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";

interface ContentProps {
  header?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

const getStyles: CSSRulesResolver = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%", // Ocupa toda a altura disponível da viewport
  },
  header: {
    flexShrink: 0, // Não encolhe
  },
  footer: {
    flexShrink: 0, // Não encolhe
  },
  content: {
    position: "relative",
    flexGrow: 1, // Ocupa todo o espaço restante
    overflowY: "auto", // Adiciona scroll caso o conteúdo ultrapasse a altura disponível
  },
});

const Content: React.FC<ContentProps> = ({ header, children, footer }) => {
  const styles = useCSSRulesWithTheme(getStyles);
  return (
    <div css={styles.container}>
      {header && <div css={styles.header}>{header}</div>}
      <div css={styles.content}>{children}</div>
      {footer && <div css={styles.footer}>{footer}</div>}
    </div>
  );
};

export default Content;
