import Notes from "../../components/Notes";
import WAChat from "../../components/WAChat";
import { Contact, Services } from "../../graphql/generated/types";
import {
  ConsultantRejectionLocation,
  ContactConversationLocation,
  ContactDetailsLocation,
  ContactsLocation,
  MyOrgLocation,
  NewContactLocation,
} from "../../Locations";
import { RouteObject } from "../../types";
import ContactDetails from "./ContactDetails";
import Contacts from "./Contacts";
import { NewContact } from "./NewContact";
import useContactDetails from "../../hooks/useContactDetails";
import { Routes, Route } from "react-router-dom";
import { ContactDetailsLayout } from "./ContactDetailsLayout";
import ContactSummary from "./ContactDetails/components/ContactSummary";
import ContactMeetings from "./ContactDetails/components/ContactMeetings";
import { isDocumentRelatedContact } from "./utils/tags";
import ContactClaims from "./ContactDetails/components/ContactClaims";
import ContactDocuments from "./ContactDetails/components/ContactDocuments";
import ContactPayments from "./ContactDetails/components/ContactPayments";
import Subcontacts from "./ContactDetails/components/Subcontacts";
import ContactConversation from "./Conversation";
import MyOrg from "../Org/MyOrg";
import PartnerRejection from "../../components/Reject/partner";
import { ContactsWrapper } from "./ContactsWrapper";
import { useFlags } from "../../hooks/useFlags";

export const getContactSubmenus = (contactData: Contact) => {
  const shouldShowDocumentRelatedTabs = isDocumentRelatedContact(
    contactData.tags || []
  );
  const { isFlagEnabled } = useFlags();

  return [
    {
      title: "Geral",
      Component: ContactSummary,
    },
    {
      title: "Documentos",
      path: "documentos",
      Component: ContactDocuments,
      disabled: !shouldShowDocumentRelatedTabs,
    },
    {
      title: "Contatos",
      path: "contatos",
      disabled: !shouldShowDocumentRelatedTabs,
      Component: Subcontacts,
    },
    {
      title: "Parcelas",
      path: "parcelas",
      disabled: !shouldShowDocumentRelatedTabs,
      Component: ContactPayments,
    },
    {
      title: "Sinistros",
      path: "sinistros",
      disabled: !shouldShowDocumentRelatedTabs,
      Component: ContactClaims,
    },
    {
      title: "Notas",
      path: "notas",
      Component: () => (
        <Notes serviceName={Services.Contacts} drawerParamKey="contactId" />
      ),
    },
    {
      title: "Chat",
      path: "chat",
      Component: WAChat,
      disabled: !contactData.mobilePhone || !isFlagEnabled("admin-only"),
    },
    {
      title: "Conversas",
      path: "conversas",
      Component: ContactMeetings,
    },
  ] as {
    title: string;
    path: string;
    Component: any;
    disabled?: boolean;
  }[];
};

export const ContactDetailsRoutes = () => {
  const { contactData } = useContactDetails();
  const submenus = getContactSubmenus(contactData);
  return (
    <Routes>
      <Route element={<ContactDetailsLayout />}>
        <Route index element={<ContactSummary />} />
        {submenus
          .filter((submenu) => !submenu.disabled && submenu.path)
          .map((submenu) => (
            <Route
              key={submenu.title}
              path={submenu.path}
              element={<submenu.Component />}
            />
          ))}
      </Route>
    </Routes>
  );
};

export const contactRoutes: RouteObject[] = [
  {
    path: ContactsLocation,
    element: <Contacts />,
    withAuth: true,
    flag: "admin-only",
  },
  {
    path: NewContactLocation,
    element: <NewContact />,
    withAuth: true,
    flag: "admin-only",
  },
  {
    path: ContactDetailsLocation + "/*",
    element: <ContactDetails />,
    withAuth: true,
    flag: "admin-only",
  },
  {
    path: ContactConversationLocation,
    // still using old layout!!!!
    element: <ContactConversation />,
    withAuth: true,
    flag: "admin-only",
  },
  {
    path: MyOrgLocation,
    element: <MyOrg />,
    withAuth: true,
  },
  {
    path: ConsultantRejectionLocation,
    element: (
      <ContactsWrapper>
        <PartnerRejection />
      </ContactsWrapper>
    ),
    withAuth: true,
  },
];
