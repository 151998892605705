/** @jsxImportSource @emotion/react */
import {
  ListDocumentsDocument,
  useListDocumentsLazyQuery,
} from "../../graphql/generated/types";
import useDocumentsFilter from "../../hooks/useDocumentsFilter";
import documentsTableColumns, {
  mapTableData,
} from "./DocumentsTable/tableColumns";
import TableProvider from "../../contexts/table";
import { DocumentsWrapper } from "./DocumentWrapper";
import DocumentsTable from "./DocumentsTable";
export const documentsTableId = "documents";

const Documents = () => {
  const documentFilters = useDocumentsFilter();
  const [listDocumentsQuery] = useListDocumentsLazyQuery();

  const fetchSheetData = async () => {
    const result = await listDocumentsQuery({
      query: ListDocumentsDocument,
      variables: {
        filter: documentFilters.composeQuery(),
      },
    });

    if (result.data?.documents) {
      return mapTableData(result.data.documents);
    }

    return [];
  };

  return (
    <DocumentsWrapper>
      <TableProvider
        tableId="documents"
        allColumns={documentsTableColumns}
        initialSort={{ id: "date", sortKey: "createdAt", direction: -1 }}
        fetchSheetData={fetchSheetData}
      >
        <DocumentsTable filter={documentFilters} />
      </TableProvider>
    </DocumentsWrapper>
  );
};

export default Documents;
