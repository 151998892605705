/** @jsxImportSource @emotion/react */
import React from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import CustomLink from "../../CustomLink";
import { ToSimulationLocation } from "../../../Locations";
import { IconNames } from "../../Icons/styles/iconNames";
import { ColorNames } from "../../../theme";
import { useAuth } from "../../../hooks/useAuth";
import Button from "../../Button";
import Icon from "../../Icons";

const getCSSRules: CSSRulesResolver = () => ({
  actions: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    marginTop: 30,
    padding: "0 12px",
  },
});

const ActionButtons: React.FC = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { shareProviderLink } = useAuth();
  return (
    <div css={styles.actions}>
      <CustomLink to={ToSimulationLocation("new", "simulacao_completo")}>
        <Button
          text="Nova Cotação"
          iconBefore
          icon={<Icon pointer name={IconNames.Add} color={ColorNames.GREEN} />}
          style="action"
        />
      </CustomLink>
      <Button
        text="Copiar Link"
        iconBefore
        icon={<Icon pointer name={IconNames.Share} color={ColorNames.GREEN} />}
        style="action"
        onClick={shareProviderLink}
      />
    </div>
  );
};

export default ActionButtons;
