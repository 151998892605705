/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import { FC } from "react";
import Typography from "../../../../../components/Typography";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import FileDetails from "../../../../../components/Details/components/Main/Grid/valueVariants/File";
import Row from "../../../../../components/Grid/Row";
import Column from "../../../../../components/Grid/Column";
import { theme } from "../../../../../theme";
import ScrollContainer from "../../../../../components/ScrollContainer";
import {
  getClaimFields,
  getClaimSpecificFields,
  getContactFields,
  getGeneralFields,
  getPaymentsFields,
  getPolicyFields,
  getProposalFields,
  Section,
} from "./sections";
import { OtherAttachments } from "./OtherAttachments";

const styles: CSSObject = {
  wrapper: {
    padding: "24px",
  },
  sectionWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
    borderRadius: theme.borderRadius.lg,
    border: `1px solid ${theme.colors.Grey[20]}`,
    boxShadow: theme.boxShadow.default,
    padding: "12px 24px 24px 24px",
    boxSizing: "border-box",
    marginTop: 24,
  },
  fileCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
    marginTop: 12,
  },
  row: { width: "100%" },
  fileDetailsBorder: {
    border: `1px solid ${theme.colors.Grey[40]}`,
    borderRadius: theme.borderRadius.lg,
    padding: 8,
  },
};

export const Attachments: FC<{}> = () => {
  const { documentData: doc } = useDocumentDetails();
  const renderSection = ({ fields, title, subsections }: Section) => (
    <div css={styles.sectionWrapper} key={title}>
      <Typography weight="bold" variant="h2" margin={"xs"}>
        {title}
      </Typography>
      <Row justify="start" css={styles.row} noMargins>
        {fields.map((file) => (
          <Column md={12} xl={6} xxl={4} css={styles.fileCard} key={file.label}>
            <Typography weight="bold" variant="h3" margin="none">
              {file.label}
            </Typography>
            {file.value ? (
              <div css={styles.fileDetailsBorder}>
                <FileDetails {...file.value} />
              </div>
            ) : (
              <Typography margin="none">-</Typography>
            )}
          </Column>
        ))}
      </Row>
      {!!subsections?.length &&
        subsections.map((subsection) => renderSection(subsection))}
    </div>
  );
  const generalFields = getGeneralFields(doc);
  return (
    <ScrollContainer css={styles.wrapper}>
      <Typography weight="bold" variant="h1" align="left" margin="none">
        Anexos
      </Typography>
      {!!generalFields?.length &&
        renderSection({ fields: generalFields, title: "Geral" })}
      {doc.proposalResult &&
        renderSection({
          fields: getProposalFields(doc.proposalResult),
          title: "Proposta",
        })}
      {!!doc.payments?.length &&
        renderSection({ fields: getPaymentsFields(doc), title: "Pagamentos" })}
      {doc.policy &&
        renderSection({
          fields: getPolicyFields(doc.policy),
          title: "Apólice",
        })}
      {!!doc.claims?.length &&
        renderSection({
          fields: getClaimFields(doc),
          title: "Sinistros",
          subsections: doc.claims.map((claim) => ({
            fields: getClaimSpecificFields(claim),
            title: `Sinistro ${claim.name}`,
          })),
        })}
      {renderSection({
        fields: [],
        title: "Contatos",
        subsections: [
          {
            fields: getContactFields(doc.farmer!),
            title: "Produtor",
          },
          doc.beneficiary &&
            doc.beneficiary._id !== doc.farmer?._id && {
              fields: getContactFields(doc.beneficiary),
              title: "Beneficiário",
            },
          doc.paymentData?.responsible &&
            doc.paymentData.responsible._id !== doc.farmer?._id && {
              fields: getContactFields(doc.paymentData.responsible),
              title: "Responsável Financeiro",
            },
        ].filter(Boolean) as Section[],
      })}
      <OtherAttachments />
    </ScrollContainer>
  );
};
