import { FC, useMemo } from "react";
import EditableTable from "../../../../../components/EditableTable";
import {
  GetCreateIntegrationSettingsFormResponseDocument,
  GetEditIntegrationSettingsFormResponseDocument,
} from "../../../../../graphql/generated/types";
import useCultureDetails from "../../../hooks/useCulture";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";

const MapfreSettingsTable: FC = () => {
  const {
    culture: { mapfreSettings, _id: cultureId },
    refetch,
  } = useCultureDetails();

  const settings = useMemo(() => {
    return (mapfreSettings ?? []).map((item) => ({ ...item, ...item.mapfre }));
  }, [mapfreSettings]);

  return (
    <DetailsSection main title="Configurações - Mapfre">
      <EditableTable
        withBorder
        data={settings}
        createQuery={GetCreateIntegrationSettingsFormResponseDocument}
        createPayload={{ formId: "mapfreSettings", cultureId }}
        editQuery={GetEditIntegrationSettingsFormResponseDocument}
        editPayload={{ formId: "mapfreSettings" }}
        onSubmit={refetch}
        formDisplay="modal"
        useV2
        columns={[
          {
            label: "Data de Plantio",
            key: "dataPlantio",
            special: "date",
          },
          {
            label: "Cobertura Adicional",
            key: "coberturaAdicional",
            special: "monetary",
          },
          {
            label: "Preço do Produto",
            key: "precoProduto",
            special: "monetary",
          },
          {
            label: "Custeio",
            key: "valCusteio",
            special: "monetary",
          },
          {
            label: "Espaçamento entre Linhas",
            key: "valEspacamentoLinhas",
          },
          {
            label: "Espaçamento entre Plantas",
            key: "valEspacamentoPlantas",
          },
          {
            label: "LMI/ha Máximo (cafezal)",
            key: "maxLmi",
            special: "monetary",
          },
          {
            label: "Operação Especial",
            key: "specialOperation",
            special: "many2one",
          },
        ]}
      />
    </DetailsSection>
  );
};

export default MapfreSettingsTable;
