import Tabs from "../../../../../components/Tabs";
import ClaimList from "./sections/ClaimList";
import ComplementaryData from "./sections/ComplementaryData";
import Inspections from "./sections/Inspections";

const tabs = [
  {
    title: "Sinistros",
    Component: ClaimList,
  },
  {
    title: "Vistorias",
    Component: Inspections,
  },
  {
    title: "Dados",
    Component: ComplementaryData,
  },
];
export const Claims = () => {
  return <Tabs tabs={tabs} />;
};
