import Tabs from "../../../../../components/Tabs";
import PaymentInfo from "./PaymentData";
import PaymentList from "./PaymentList";

const tabs = [
  {
    title: "Dados",
    Component: PaymentInfo,
  },
  {
    title: "Parcelas",
    Component: PaymentList,
  },
];

export const DocumentPayments = () => {
  return <Tabs tabs={tabs} />;
};
