/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { Row } from "react-grid-system";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import useConfirmedAction from "../../../../../hooks/useConfirmedAction";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import InsuranceInfo from "./components/InsuranceInfo";
import Selects from "./components/Selects";
import {
  DocumentStages,
  DocumentStatus,
  useCreateDocumentRenewalFormResponseMutation,
  useGetDocumentRejectionFormResponseLazyQuery,
} from "../../../../../graphql/generated/types";
import { useModalStore } from "../../../../../components/Modal/context/ModalStore";
import Form from "../../../../../modules/Forms";
import FormTitle from "../../../../../modules/Forms/components/FormTitle";
import useDocumentOrder from "../../../../../hooks/useDocumentOrder";
import { ToDocumentDetailsLocation } from "../../../../../Locations";
import { useNavigate } from "react-router-dom";
import FormV2 from "../../../../../modules/Forms/form.v2";

const DocumentSummary: FC = () => {
  const {
    documentData: { _id, stage, status, archived },
    handleToggleArchived,
    handleDuplicateDocument,
    refetch,
  } = useDocumentDetails();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalStore();

  const onArchive = useConfirmedAction(
    `Tem certeza de que deseja ${
      archived ? "desarquivar" : "arquivar"
    } este documento?`,
    {
      onConfirm: [{ onClick: handleToggleArchived }],
    }
  );
  const [renewDocumentMutation] =
    useCreateDocumentRenewalFormResponseMutation();

  const handleRenewDocument = async () => {
    const { data } = await renewDocumentMutation({
      variables: {
        documentId: _id!,
      },
    });
    const { formResponse, documentId } =
      data!.createDocumentRenewalFormResponse;
    if (documentId) {
      navigate(ToDocumentDetailsLocation(documentId));
    }
    if (formResponse) {
      openModal(
        <FormV2
          formResponseId={formResponse!.formResponseId}
          onSubmit={({ objectId }: { objectId: string }) => {
            closeModal();
            navigate(ToDocumentDetailsLocation(objectId));
          }}
          onCancel={closeModal}
        />
      );
    }
  };

  const [getDocumentRejectionFormResponse] =
    useGetDocumentRejectionFormResponseLazyQuery();
  const handleRejectDocument = async () => {
    const { data } = await getDocumentRejectionFormResponse({
      variables: {
        documentId: _id!,
      },
    });
    openModal(
      <>
        <FormTitle title="Recusar cotação" />
        <Form
          formId="documentRejection"
          docId={data!.getDocumentRejectionFormResponse!.formResponseId!}
          onCancel={closeModal}
          onSubmit={() => {
            refetch(), closeModal();
          }}
        />
      </>
    );
  };

  const currentStage = useDocumentOrder(stage!);
  const policyStage = useDocumentOrder(DocumentStages.Policy);

  const footerButtons = [
    {
      text: archived ? "Desarquivar" : "Arquivar",
      iconName: archived ? IconNames.Unarchive : IconNames.Archive,
      onClick: onArchive,
      disabled: currentStage >= policyStage,
      flag: "admin-only",
    },
    {
      text: "Recusar",
      iconName: IconNames.Rejected,
      onClick: handleRejectDocument,
      disabled:
        status === DocumentStatus.Rejected || currentStage >= policyStage,
      flag: "admin-only",
    },
    {
      text: "Duplicar",
      iconName: IconNames.Duplicate,
      onClick: handleDuplicateDocument,
      flag: "admin-only",
    },
    {
      text: "Renovar",
      iconName: IconNames.Redo,
      onClick: handleRenewDocument,
      flag: "admin-only",
      disabled: currentStage < policyStage,
    },
  ];

  return (
    <DetailsSection
      title="Dados gerais"
      noTitle
      main
      footerButtons={footerButtons}
    >
      <Row>
        <InsuranceInfo />
        <Selects />
      </Row>
    </DetailsSection>
  );
};

export default DocumentSummary;
