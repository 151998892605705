/** @jsxImportSource @emotion/react */
import { HomeLocation, ToDocumentDetailsLocation } from "../../Locations";
import { Services } from "../../graphql/generated/types";
import ModalStoreProvider from "../../components/Modal/context/ModalStore";
import { useFlags } from "../../hooks/useFlags";
import AppLayout from "../../components/AppLayout/AppLayout";
import LeftMenu from "../../components/LeftMenu/LeftMenu";
import RightMenu from "../../components/RightMenu/RightMenu";
import { FC, ReactNode } from "react";
import useDocumentDetails from "../../hooks/useDocumentDetails";
import { useDocumentSubmenus } from "../../hooks/useDocumentSubmenus";
import { useUIStore } from "../../hooks/useUIStore";
import { useDefaultMenuItems } from "../../hooks/useDefaultMenuItems";

export const DocumentsWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const { documentData } = useDocumentDetails();
  const { isFlagEnabled } = useFlags();
  const getMenuItems = () => {
    const menuItens = useDefaultMenuItems(isFlagEnabled("admin-only"));
    const submenus = useDocumentSubmenus({
      documentId: documentData._id,
      stage: documentData.stage,
      status: documentData.status,
    });
    return menuItens.map((item) => {
      if (item.location === HomeLocation) {
        return {
          ...item,
          submenus: submenus.map((submenu) => ({
            title: submenu.title,
            location: submenu.location!,
            disabled: submenu.disabled,
          })),
        };
      }
      return item;
    });
  };
  const {
    isConfirmationModalOpen,
    renderConfirmationModal,
    isSubmittedModalOpen,
    renderSubmittedModal,
  } = useUIStore();

  return (
    <AppLayout
      leftMenu={<LeftMenu menuItems={getMenuItems()} />}
      rightMenu={
        <RightMenu
          docIdRedirectLocation={(docId) => ToDocumentDetailsLocation(docId)}
          serviceName={Services.Documents}
        />
      }
    >
      <ModalStoreProvider>
        {isConfirmationModalOpen && renderConfirmationModal()}
        {isSubmittedModalOpen && renderSubmittedModal()}
        {children}
      </ModalStoreProvider>
    </AppLayout>
  );
};
