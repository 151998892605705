/** @jsxImportSource @emotion/react */

import { FC, useEffect, useMemo, useState } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { ColorNames, getThemeColorFromAlias, theme } from "../../theme";
import Typography from "../Typography";
import ModalStoreProvider from "../Modal/context/ModalStore";
import Row from "../Grid/Row";
import MobileGrid from "../Carousel";
import Column from "../Grid/Column";
import ScrollContainer from "../ScrollContainer";
import useIsMobile from "../../hooks/useIsMobile";

interface Tab {
  title: string;
  Component: FC;
}

interface TabsProps {
  tabs: Tab[];
  color?: ColorNames;
}

const tabsHeight = 45;
const containerHeight = `calc(100% - ${tabsHeight}px)`;

const getCSSRules: CSSRulesResolver<{
  tabColor?: string;
  active?: boolean;
}> = ({ tabColor, active }) => ({
  container: {
    overflow: "hidden",
    height: "100%",
    width: "100%",
    position: "relative" as const,
    boxSizing: "border-box" as const,
  },
  tabs: {
    height: tabsHeight,
    width: "100%",
    backgroundColor: theme.colors.LightGrey[100],
    // borderBottom: `px solid ${theme.colors.Black[100]}`,
    marginBottom: 0,
    position: "relative" as const,
  },
  tab: {
    height: "100%",
    cursor: "pointer",
    borderBottom: "4px solid",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: active ? tabColor : "transparent",
    "&:hover": !active && {
      borderColor: theme.colors.Grey[40],
    },
  },
  content: {
    height: containerHeight,
    position: "relative" as const,
    boxSizing: "border-box" as const,
    padding: 12,
  },
});

const Tabs: FC<TabsProps> = ({ tabs, color = ColorNames.BLACK }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabColor = getThemeColorFromAlias(color, theme.colors)!;
  const styles = useCSSRulesWithTheme(getCSSRules);
  const tabStyles = (active: boolean) =>
    useCSSRulesWithTheme(getCSSRules, { tabColor, active }).tab;

  const isMobile = useIsMobile();

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  // set the active tab to the first tab
  useEffect(() => {
    if (activeTab > tabs.length - 1) {
      setActiveTab(0);
    }
  }, [tabs]);

  const Content = useMemo(() => tabs[activeTab]?.Component, [activeTab, tabs]);

  const tabColumns = tabs.map((tab, index) => (
    <div
      css={tabStyles(activeTab === index)}
      onClick={() => handleTabClick(index)}
    >
      <Typography
        variant={"textLarge"}
        uppercase
        weight={activeTab === index ? "bold" : "regular"}
      >
        {tab.title}
      </Typography>
    </div>
  ));

  return (
    <ModalStoreProvider>
      <div css={styles.container}>
        <div>{isMobile && <MobileGrid columns={tabColumns} />}</div>
        {!isMobile && (
          <Row justify="start" align="end" css={styles.tabs} noGutters>
            {tabColumns.map((tab, index) => (
              <Column key={`tab-column-${index}`} xs={2}>
                {tab}
              </Column>
            ))}
          </Row>
        )}
        <div css={styles.content}>
          <ScrollContainer>{Content && <Content />}</ScrollContainer>
        </div>
      </div>
    </ModalStoreProvider>
  );
};

export default Tabs;
