import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ToDirectDocumentDetailsLocation,
  ToDirectSimulationLocation,
} from "../../../Locations";
import { useAuth } from "../../../hooks/useAuth";
import { useCreateFormResponseV2Mutation } from "../../../graphql/generated/types";
import { useUIStore } from "../../../hooks/useUIStore";
import FormV2 from "../../../modules/Forms/form.v2";

const NewDirectSimulation = () => {
  const { formId, docId } = useParams();
  const { loaded, updateToken } = useAuth();
  const { openSubmittedModal } = useUIStore();
  const navigate = useNavigate();

  const [createFormResponseMutation] = useCreateFormResponseV2Mutation();

  useEffect(() => {
    if (formId && docId === "new" && loaded) {
      asyncCreateFormResponse(formId);
    }
  }, [formId, docId, loaded]);

  const asyncCreateFormResponse = async (formId: string) => {
    try {
      const { data } = await createFormResponseMutation({
        variables: {
          formId: formId!,
          metadata: {
            originatorSlug: localStorage.getItem("originatorSlug"),
          },
        },
      });
      navigate(ToDirectSimulationLocation(data!.createFormResponseV2, formId), {
        replace: true,
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const onSubmit = async ({
    documentId,
    token,
    hasResults,
  }: {
    documentId: string;
    token: string;
    hasResults: boolean;
  }) => {
    if (token) {
      await updateToken(token);
    }
    if (hasResults) {
      navigate(ToDirectDocumentDetailsLocation(documentId));
    } else {
      openSubmittedModal(
        "Suas informações foram registradas! Entraremos em contato em breve."
      );
    }
  };

  return (
    <>
      {docId && formId && docId !== "new" && (
        <FormV2 formId={formId} formResponseId={docId} onSubmit={onSubmit} />
      )}
    </>
  );
};

export default NewDirectSimulation;
