/** @jsxImportSource @emotion/react */
import React from "react";
import { ViewFilterControl } from "../../../../../components/ViewFilterControl";
import { Services } from "../../../../../graphql/generated/types";
import { TModelFilter } from "../../../../../hooks/useModelFilter";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../../hooks/useCSSRulesWithTheme";
import NestedMenu, {
  NestedMenuOption,
} from "../../../../../components/NestedMenu/NestedMenu";
import SearchBar from "../../../../../components/SearchBar";
import { useTableContext } from "../../../../../contexts/table";
import DocumentsFilter from "../DocumentsFilter/DocumentsFilter";

interface DocumentsTableHeaderProps {
  filters: TModelFilter;
  nestedMenuOptions: NestedMenuOption[];
}

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    width: "100%",
    paddingBottom: "40px",
    paddingRight: "12px",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    gap: 12,
  },
  right: {
    display: "flex",
    gap: 12,
  },
  search: {
    width: 600,
  },
});

const DocumentsTableHeader: React.FC<DocumentsTableHeaderProps> = ({
  filters,
  nestedMenuOptions,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { actions } = useTableContext();
  return (
    <div css={styles.container}>
      <ViewFilterControl
        placeholder="Todos os Documentos"
        filters={filters}
        service={Services.Documents}
      />
      <div css={styles.right}>
        <div css={styles.search}>
          <SearchBar
            filter={filters}
            dropdown={<DocumentsFilter filter={filters} />}
          />
        </div>
        {actions.activeView === "table" && !!nestedMenuOptions.length && (
          <NestedMenu id="documents-menu" options={nestedMenuOptions} />
        )}
      </div>
    </div>
  );
};

export default DocumentsTableHeader;
