/** @jsxImportSource @emotion/react */
import React from "react";
import { ViewFilterControl } from "../../components/ViewFilterControl";
import { Services } from "../../graphql/generated/types";
import { TModelFilter } from "../../hooks/useModelFilter";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import NestedMenu, {
  NestedMenuOption,
} from "../../components/NestedMenu/NestedMenu";
import SearchBar from "../../components/SearchBar";
import { useTableContext } from "../../contexts/table";
import PaymentListFilter from "./PaymentListFilter";

interface PaymentListHeaderProps {
  filters: TModelFilter;
  nestedMenuOptions: NestedMenuOption[];
}

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    width: "100%",
    marginBottom: "40px",
    paddingRight: "12px",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  right: {
    display: "flex",
    gap: 12,
  },
  search: {
    width: 400,
  },
});

const PaymentListHeader: React.FC<PaymentListHeaderProps> = ({
  filters,
  nestedMenuOptions,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { actions } = useTableContext();
  return (
    <div css={styles.container}>
      <ViewFilterControl
        placeholder="Todos os Pagamentos"
        filters={filters}
        service={Services.Payments}
      />
      <div css={styles.right}>
        <div css={styles.search}>
          <SearchBar
            filter={filters}
            dropdown={<PaymentListFilter filter={filters} />}
          />
        </div>
        {actions.activeView === "table" && !!nestedMenuOptions.length && (
          <NestedMenu id="payments-menu" options={nestedMenuOptions} />
        )}
      </div>
    </div>
  );
};

export default PaymentListHeader;
