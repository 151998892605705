import { FC, useMemo } from "react";
import {
  DocumentStages,
  DocumentStatus,
  GetCreateProposalOnDocumentFormResponseDocument,
  GetEditProposalOnDocumentFormResponseDocument,
  Result,
  Services,
} from "../../../../../graphql/generated/types";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import useDocumentOrder from "../../../../../hooks/useDocumentOrder";
import EditableSection from "../../../../../components/EditableSection";
import useFooterButtons from "./hooks/useFooterButtons";
import Typography from "../../../../../components/Typography";
import useDocumentColor from "../../../../../hooks/useDocumentColor";
import QuotationCard, {
  useQuotationCardColumns,
} from "../../components/QuotationCard/index";
import { Row } from "react-grid-system";
import Column from "../../../../../components/Grid/Column";
import { DetailsRowProps } from "../../../../../components/Details/components/Main/Grid/DetailsRow";
import { DetailsRowValueVariants } from "../../../../../components/Details/components/Main/Grid/DetailsRowValue";

const ProposalDetails: FC<{
  proposalResult?: Result;
}> = ({ proposalResult }) => {
  const {
    documentData: { _id, stage, status },
    refetch,
  } = useDocumentDetails();

  const proposalInfo = useMemo(() => {
    if (!proposalResult?._id) return [];
    return [
      {
        label: "ID da proposta",
        value: proposalResult.externalId,
      },
      proposalResult?.proposalFile?.fileKey && {
        label: "Proposta",
        variant: DetailsRowValueVariants.File,
        value: {
          file: {
            fileName: proposalResult.proposalFile?.fileName,
          },
          objectId: proposalResult._id,
          fieldPath: "proposalFile",
          serviceName: Services.Results,
        },
      },
      proposalResult?.proposalSketchMap?.fileKey && {
        label: "Croqui da área",
        variant: DetailsRowValueVariants.File,
        value: {
          file: {
            fileName: proposalResult.proposalSketchMap?.fileName,
          },
          objectId: proposalResult._id,
          fieldPath: "proposalSketchMap",
          serviceName: Services.Results,
        },
      },
    ].filter(Boolean) as DetailsRowProps[];
  }, [proposalResult, _id]);

  const currentStage = useDocumentOrder(stage!, status!);
  const policyStage = useDocumentOrder(DocumentStages.Policy);
  const pendingProposalStage = useDocumentOrder(
    DocumentStages.Proposal,
    DocumentStatus.Pending
  );
  const footerButtons =
    proposalResult?.archived === true ? [] : useFooterButtons();
  const documentColor = useDocumentColor(stage!);

  const editQuery = useMemo(
    () =>
      !proposalResult?.archived === true && currentStage < policyStage
        ? GetEditProposalOnDocumentFormResponseDocument
        : undefined,
    [currentStage, policyStage]
  );

  const createQuery = useMemo(
    () =>
      currentStage === pendingProposalStage
        ? GetCreateProposalOnDocumentFormResponseDocument
        : undefined,
    [currentStage, pendingProposalStage]
  );

  return (
    <EditableSection
      title="Proposta"
      noTitle
      main
      createPayload={{ documentId: _id! }}
      createQuery={createQuery}
      editQuery={editQuery}
      objectId={proposalResult?._id}
      footerButtons={footerButtons}
      addText={"Adicionar Proposta"}
      useV2
      addFlag="admin-only"
      editFlag="admin-only"
      onSubmit={() => refetch()}
    >
      {!proposalInfo.length ? (
        <Typography variant="textMedium" uppercase color={documentColor}>
          Não há proposta adicionada
        </Typography>
      ) : (
        <>
          <DetailsSectionColumns columns={[proposalInfo]} />
          <Row>
            <Column
              xs={12}
              xl={useQuotationCardColumns(proposalResult!) ? 12 : 6}
              xxl={useQuotationCardColumns(proposalResult!) ? 12 : 4}
            >
              <QuotationCard result={proposalResult!} />
            </Column>
          </Row>
        </>
      )}
    </EditableSection>
  );
};

export default ProposalDetails;
