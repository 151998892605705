/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import Card, { CardFC } from "../../../components/CardList/components/Card";
import Icon from "../../../components/Icons";
import { cardDocumentStatusFilters } from "../../../filters/documents";
import Responsibles from "./components/Responsibles";
import useDocumentColor from "../../../hooks/useDocumentColor";
import Tooltip from "../../../components/Tooltip";
import { formatISODate } from "../../../utils/dateUtils";
import {
  DocumentStatus,
  InsuranceTypeGroupEnum,
  ListDocumentsQuery,
} from "../../../graphql/generated/types";
import { documentStatusLabels } from "../../../shared/documents/documentStatus";

const DocumentCard: CardFC<
  NonNullable<NonNullable<ListDocumentsQuery["documents"]>[number]>
> = ({ data, active, onClick }) => {
  const insuredInfo = useMemo(() => {
    if (data.insuranceTypeGroup === InsuranceTypeGroupEnum.Agricultural) {
      return `${data.description!} ${
        data.crop?.area && "//" + data.crop?.area + " ha"
      }`;
    } else {
      return data.description!;
    }
  }, [data]);
  const cardData = useMemo(() => {
    const uniquePropertyLocations = Object.keys(
      Object.fromEntries(
        data.properties?.map(({ address }) => [
          `${address?.city} - ${address?.state}`,
          true,
        ]) || []
      )
    ).join(", ");
    const propertyInfo = data.properties
      ? data.properties.length > 1
        ? data.properties.length + " propriedades // " + uniquePropertyLocations
        : data.properties[0].displayName!
      : "";
    const datesLine = data.policy?.endDate
      ? "Vigência: " + formatISODate(data.policy.endDate)
      : "Atualizado em: " + formatISODate(data.updatedAt!);
    return {
      line1: data.farmer?.name || "",
      line2: insuredInfo,
      line3: propertyInfo,
      line4: datesLine,
    };
  }, [data, insuredInfo]);

  const cardColor = useDocumentColor(data.claimStatus || data.stage!);
  const statusIcon = () => {
    return cardDocumentStatusFilters().find(
      (option) => option.filter.status === data.status
    )?.iconName;
  };

  const responsibles = useMemo(() => {
    return [
      data.originator?.name || "",
      data.commercialResponsible?.name || "",
      data.operationalResponsible?.name || "",
    ];
  }, [data]);

  const detailTopRight = useMemo(() => {
    return (
      <div css={{ textAlign: "right" }}>
        <Tooltip
          text={documentStatusLabels[data.status! as DocumentStatus]}
          id={`status-${data._id!}`}
        >
          <Icon name={statusIcon()!} color={cardColor} />
        </Tooltip>
      </div>
    );
  }, [data.status, cardColor, data._id]);

  return (
    <Card
      cardData={cardData}
      detailTop={detailTopRight}
      detailBottom={
        <Responsibles
          responsibles={responsibles}
          color={cardColor}
          docId={data._id!}
        />
      }
      color={cardColor}
      active={active}
      onClick={onClick}
    />
  );
};

export default DocumentCard;
