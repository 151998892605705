import { useMemo } from "react";
import Tabs from "../../../../../components/Tabs";
import { DocumentStatus } from "../../../../../graphql/generated/types";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import ArchivedPolicies from "./ArchivedPolicies";
import PolicyData from "./PolicyData";

export const Policy = () => {
  const {
    documentData: { policy, status },
  } = useDocumentDetails();
  const tabs = useMemo(() => {
    return [
      {
        title:
          status === DocumentStatus.Cancelled ? "Cancelada" : "Transmitida",
        Component: () => <PolicyData policy={policy || undefined} />,
      },
      {
        title: "Arquivadas",
        Component: ArchivedPolicies,
      },
    ];
  }, [policy, status]);

  return <Tabs tabs={tabs} />;
};
