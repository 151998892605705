/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";

//hooks
import { useAuth } from "./hooks/useAuth";
import { useRootStore } from "./hooks/useRootStore";
import { useErrorHandler } from "./hooks/useErrorHandler";
import { useFlags } from "./hooks/useFlags";

//views
import Login from "./views/Login";
import ErrorPage from "./views/ErrorPage";
import DirectSimulations from "./views/Forms/DirectSimulations";
import DirectSimulationForm from "./views/Forms/DirectSimulations/NewDirectSimulation";
import SharedForm from "./views/SharedForm";
import TermsAndConditions from "./views/TermsAndConditions";
import Documents from "./views/Documents";
import DirectDocumentDetails from "./views/Documents/DirectDocumentDetails";
import PendingUser from "./views/PendingUser";
import DirectQuotationForm from "./views/Forms/DirectQuotations/DirectQuotationForm";
import DirectQuotations from "./views/Forms/DirectQuotations";
import PartnersDashboard from "./views/Dashboard/PartnersDashboard";
import ComplementaryDataForm from "./views/Forms/ComplementaryDataForm";
import NfeUploadForm from "./views/Forms/NfeUploadForm";
import KmlUploadForm from "./views/Forms/KmlUploadForm";

//locations
import {
  LoginLocation,
  HomeLocation,
  DirectSimulationsLocation,
  DirectSimulationLocation,
  TermsAndConditionsLocation,
  SharedFormLocation,
  PendingRegisterLocation,
  ToLoginLocationWithRedirect,
  DirectQuotationFormLocation,
  DirectQuotationsLocation,
  DirectDocumentDetailsLocation,
  PartnersDashboardLocation,
  ComplementaryDataFormLocation,
  NfeUploadFormLocation,
  KmlUploadFormLocation,
  OrgCompleteRegisterLocation,
} from "./Locations";

//components
import PageTitle from "./components/PageTitle";

//types
import { UserRoles } from "./graphql/generated/types";
import { locationLabels } from "./utils/pageLabels";
import { RouteObject } from "./types";
import { contactRoutes } from "./views/Contact/Routes";
import { cultureRoutes } from "./views/Cultures/Routes";
import { registerRoutes } from "./views/Register/Routes";
import { orgIsCompleted } from "./views/Contact/utils/tags";
import { paymentRoutes } from "./views/Payments/Routes";
import { documentRoutes } from "./views/Documents/Routes";

export enum HOMETAB {
  SIMULATIONS,
  QUOTATIONS,
  PROPOSALS,
  INSURANCES,
}

export enum PARTNERSTAB {
  CONSULTANTS,
  REGISTEREDPARTNERS,
  ACTIVEPARTNERS,
}

export enum DetailsTab {
  Notes = "notas",
  Chat = "chat",
}

// forms
const directSimulationRoutes: RouteObject[] = [
  {
    path: DirectSimulationLocation,
    element: <DirectSimulationForm />,
    withAuth: false,
  },
];
const directQuotationRoutes: RouteObject[] = [
  {
    path: DirectQuotationFormLocation,
    element: <DirectQuotationForm />,
    withAuth: false,
  },
];

const appRoutes: RouteObject[] = [
  // public
  {
    path: LoginLocation,
    element: <Login />,
    withAuth: false,
  },
  {
    path: SharedFormLocation,
    element: <SharedForm />,
    withAuth: false,
  },
  {
    path: DirectSimulationsLocation,
    element: <DirectSimulations />,
    withAuth: false,
    rightSideRoutes: directSimulationRoutes,
  },
  {
    path: DirectDocumentDetailsLocation,
    element: <DirectDocumentDetails />,
    withAuth: false,
  },
  {
    path: ComplementaryDataFormLocation,
    element: <ComplementaryDataForm />,
    withAuth: false,
  },
  {
    path: NfeUploadFormLocation,
    element: <NfeUploadForm />,
    withAuth: false,
  },
  {
    path: KmlUploadFormLocation,
    element: <KmlUploadForm />,
    withAuth: false,
  },
  {
    path: DirectQuotationsLocation,
    element: <DirectQuotations />,
    withAuth: false,
    rightSideRoutes: directQuotationRoutes,
  },

  // register
  {
    path: TermsAndConditionsLocation,
    element: <TermsAndConditions />,
    withAuth: true,
  },
  {
    path: PendingRegisterLocation,
    element: <PendingUser />,
    withAuth: true,
  },

  // home
  {
    path: HomeLocation,
    element: <Documents />,
    withAuth: true,
  },

  // documents
  ...documentRoutes,

  // partners
  {
    path: PartnersDashboardLocation,
    element: <PartnersDashboard />,
    withAuth: true,
    flag: "partners-view",
  },

  ...cultureRoutes,

  ...contactRoutes,

  ...registerRoutes,

  ...paymentRoutes,

  { path: "*", element: <ErrorPage />, withAuth: false },
];

const renderDetailsTabRoutes = (drawerRoute: RouteObject, i: number) =>
  drawerRoute.detailsTabRoutes?.map((detailsTab, j: number) => (
    <Route
      key={`${i}-${j}`}
      path={detailsTab.path}
      element={<CustomRoute {...detailsTab} />}
    />
  ));

const renderDrawerRoutes = (route: RouteObject, i: number, j: number = 0) =>
  route.drawerRoutes?.map((drawerRoute, k: number) => (
    <Route
      key={`${i}-${j}-${k}`}
      path={drawerRoute.path}
      element={<CustomRoute {...drawerRoute} />}
    >
      {renderDetailsTabRoutes(drawerRoute, i)}
    </Route>
  ));

const renderRightSideRoutes = (route: RouteObject, i: number) =>
  route.rightSideRoutes?.map((rightSideRoute: RouteObject, j: number) => (
    <Route
      key={`${i}-${j}`}
      path={rightSideRoute.path}
      element={<CustomRoute {...rightSideRoute} />}
    ></Route>
  ));

const renderAppRoutes = (routes: RouteObject[]) =>
  routes.map((route: RouteObject, i: number) => (
    <Route key={i} path={route.path} element={<CustomRoute {...route} />}>
      {renderRightSideRoutes(route, i)}
      {renderDrawerRoutes(route, i)}
    </Route>
  ));

export const AppRoutes = () => <Routes>{renderAppRoutes(appRoutes)}</Routes>;

interface CustomRouteProps {
  withAuth?: boolean;
  drawerRoutes?: CustomRouteProps[];
  element?: ReactNode;
  dataLoader?: any;
  computedMatch?: any;
  flag?: string;
  path: string;
}

function CustomRoute({
  withAuth,
  element,
  dataLoader,
  flag,
  path,
  ...rest
}: CustomRouteProps) {
  const errorHandler = useErrorHandler();
  const authContext = useAuth();
  const location = useLocation();
  const params = useParams();
  const rootStore = useRootStore();
  const { isFlagEnabled, flagsLoaded } = useFlags();
  let navigate = useNavigate();
  const publicLocations = appRoutes
    .map((appRoute) => !appRoute.withAuth && appRoute.path)
    .filter((it) => !!it);
  useEffect(() => {
    return () => {
      // clear errors
      // errorStore.clearErrors();
    };
  }, []);

  useEffect(() => {
    //auth token not fully loaded
    if (!authContext.loaded) {
      return;
    }
    // user is logged in redirect to app home
    if (authContext.isLoggedIn && location.pathname === LoginLocation) {
      navigate(HomeLocation, { replace: true });
      return;
    }
    // user hasn't been approved
    if (authContext.isLoggedIn && authContext.user.status === "registered") {
      !publicLocations.includes(location.pathname) &&
        location.pathname !== PendingRegisterLocation &&
        navigate(PendingRegisterLocation);
      return;
    }
    // user hasn't signed terms and conditions
    if (
      authContext.user?._id &&
      authContext.isLoggedIn &&
      !authContext.user.signedDate &&
      !publicLocations.includes(location.pathname)
    ) {
      if (
        authContext.user.role === UserRoles.OrgAdmin &&
        !orgIsCompleted(authContext.user)
      ) {
        if (location.pathname !== OrgCompleteRegisterLocation)
          navigate(OrgCompleteRegisterLocation);
        return;
      } else if (location.pathname !== TermsAndConditionsLocation) {
        navigate(TermsAndConditionsLocation);
        return;
      }
    }
    //user must be logged out
    if (
      (withAuth && !authContext.bearerToken) ||
      (withAuth && authContext.user?._id && !authContext.isLoggedIn) ||
      (withAuth && authContext.loaded && !authContext.tokenPayload?.user)
    ) {
      const redirectPath = location.pathname;
      authContext.signOut();
      navigate(ToLoginLocationWithRedirect(redirectPath), { replace: true });
      return;
    }
    //view is flagged and user doesn't have access to it
    if (flag) {
      if (!flagsLoaded) {
        return;
      }
      if (!isFlagEnabled(flag)) {
        throw new Error("cannot access page");
      }
    }
    if (dataLoader && location && params) {
      //all good with auth, let's run the view data loader
      dataLoader({ rootStore, params, location }).catch(errorHandler);
    }
  }, [
    withAuth,
    authContext.bearerToken,
    authContext.isLoggedIn,
    authContext.loaded,
    authContext.user.signedDate,
    authContext.user.status,
    flagsLoaded,
    navigate,
    dataLoader,
    location,
    params,
  ]);

  return (
    <>
      <PageTitle title={locationLabels[path]} />
      {element}
    </>
  );
}
