/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorNames, theme } from "../../theme";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import Typography from "../Typography";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { IconSizes } from "../Icons/styles/iconSizes";

interface AvatarProps {
  name: string;
  color?: ColorNames;
  size?: IconSizes;
  filled?: boolean;
  onClick?: () => void;
}

const getCSSRules: CSSRulesResolver<{
  size: number;
  filled: boolean;
  onClick: (() => void) | undefined;
}> = ({ size, onClick }) => ({
  container: {
    position: "relative" as "relative",
    display: "flex",
    justifyContent: "center",
    cursor: "auto",
    borderRadius: "50%",
    width: size,
    height: size,
    "&:hover": {
      backgroundColor: onClick
        ? theme.colors.LightGrey[100]
        : theme.colors.White[100],
    },
  },
  initialsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute" as "absolute",
    height: size,
  },
});

const getInitials = (name: string) => {
  const parts = name.split(" ");

  const first = parts[0];
  const last = parts.length > 1 ? parts[parts.length - 1] : "";

  return `${first[0]}${last[0] || ""}`;
};

const Avatar: FC<AvatarProps> = ({
  name,
  color = ColorNames.GREEN,
  size = IconSizes.Medium,
  filled = false,
  onClick,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules, { size, filled, onClick });
  return (
    <div css={styles.container} onClick={onClick}>
      {name ? (
        <>
          <Icon
            name={filled ? IconNames.AvatarFilled : IconNames.Avatar}
            size={size}
            color={color}
          />
          <div css={styles.initialsContainer}>
            <Typography
              variant="textSmall"
              weight="bold"
              size={0.4 * size}
              color={filled ? ColorNames.WHITE : color}
              css={{ cursor: onClick ? "pointer" : "default" }}
            >
              {getInitials(name)}
            </Typography>
          </div>
        </>
      ) : (
        <Icon
          name={IconNames.Unassigned}
          size={size}
          color={ColorNames.GREY}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default Avatar;
