import { IconNames } from "../components/Icons/styles/iconNames";
import {
  ClaimStatus,
  ContactTags,
  DocumentStages,
  DocumentStatus,
  InsuranceTypeGroupEnum,
  PropostaProviders,
  Services,
} from "../graphql/generated/types";
import { genericToFilter, responsibleFilter, stateFilter } from "./common";
import { DateDisplays, TFilter } from "./types";

export const dashboardDocumentTypeFilters = (): TFilter[] => [
  insuranceTypeGroupFilter,
];

export const dashboardDocumentFilters = (): TFilter[] => [
  responsibleFilter,
  documentOriginatorFilter,
  documentProviderFilter,
  documentCultureFilter,
  stateFilter,
];

export const cardDocumentStatusFilters = () => documentStatusFilter.options;

export const getStatusIcon = (status: DocumentStatus) => {
  const statusIcon = documentStatusFilter.options.find(
    (option) => option.filter?.status === status
  )?.iconName;
  return statusIcon;
};

const documentFilters = (): TFilter[] => [
  documentStageFilter,
  responsibleFilter,
  documentOriginatorFilter,
  documentStatusFilter,
  documentProviderFilter,
  documentCultureFilter,
  harvestFilter,
  stateFilter,
  insuranceTypeGroupFilter,
  claimStatusFilter,
  createdAtFilter,
  updatedAtFilter,
  policyEndFilter,
];
export default documentFilters;

export const documentStageFilter: TFilter<DocumentStages> = {
  label: "Etapa",
  value: [],
  urlParam: "etapa",
  name: "stage",
  selectMultiValueDisplay: "all",
  options: [
    {
      name: DocumentStages.Simulation,
      value: "cotacao",
      label: "Cotações",
      filter: {
        stage: DocumentStages.Simulation,
      },
    },
    {
      name: DocumentStages.Proposal,
      value: "proposta",
      label: "Propostas",
      filter: {
        stage: DocumentStages.Proposal,
      },
    },
    {
      name: DocumentStages.Policy,
      value: "apolice",
      label: "Apólices",
      filter: {
        stage: DocumentStages.Policy,
      },
    },
  ],
};

export const documentStatusFilter: TFilter<DocumentStatus | "expired"> = {
  label: "Status",
  value: [],
  urlParam: "status",
  name: "status",
  selectOptionDisplay: "icon",
  selectMenuListDisplay: "buttons",
  selectMultiValueDisplay: "all",
  options: [
    {
      name: DocumentStatus.Incomplete,
      value: "incompletas",
      label: "Incompletas",
      filter: {
        status: DocumentStatus.Incomplete,
      },
      iconName: IconNames.Incomplete,
      isDefaultOption: true,
    },
    {
      name: DocumentStatus.Pending,
      value: "pendentes",
      label: "Pendentes",
      filter: {
        status: DocumentStatus.Pending,
      },
      iconName: IconNames.Pending,
      isDefaultOption: true,
    },
    {
      name: DocumentStatus.Done,
      value: "finalizadas",
      label: "Finalizadas",
      filter: {
        status: DocumentStatus.Done,
      },
      iconName: IconNames.Done,
      isDefaultOption: true,
    },
    {
      name: DocumentStatus.Sent,
      value: "enviadas",
      label: "Enviadas",
      filter: {
        status: DocumentStatus.Sent,
      },
      iconName: IconNames.Sent,
      isDefaultOption: true,
    },
    {
      name: DocumentStatus.Effective,
      value: "vigentes",
      label: "Vigentes",
      filter: {
        status: DocumentStatus.Effective,
      },
      iconName: IconNames.Valid,
      isDefaultOption: true,
    },
    {
      name: DocumentStatus.NonEffective,
      value: "nao-vigentes",
      label: "Não Vigentes",
      filter: {
        status: DocumentStatus.NonEffective,
      },
      iconName: IconNames.Expired,
      isDefaultOption: true,
    },
    {
      name: DocumentStatus.UnderAnalysis,
      value: "em analise",
      label: "Em Análise",
      filter: {
        status: DocumentStatus.UnderAnalysis,
      },
      iconName: IconNames.Done,
      isDefaultOption: true,
    },
    {
      name: DocumentStatus.Rejected,
      value: "recusadas",
      label: "Recusadas",
      filter: {
        status: DocumentStatus.Rejected,
      },
      iconName: IconNames.Rejected,
    },
    {
      value: "arquivadas",
      label: "Arquivadas",
      filter: {
        archived: true,
      },
      iconName: IconNames.Archive,
    },
    {
      name: DocumentStatus.Cancelled,
      value: "canceladas",
      label: "Canceladas",
      filter: {
        status: DocumentStatus.Cancelled,
      },
      iconName: IconNames.Close,
    },
  ],
};

const documentOriginatorFilter: TFilter = {
  label: "Parceiro",
  value: [],
  urlParam: "parceiro",
  name: "originator",
  many2Many: {
    serviceName: Services.Contacts,
    domain: {
      tags: { $in: [ContactTags.Consultant, ContactTags.Organization] },
    },
  },
  toFilter: genericToFilter("originatorId"),
  options: [
    {
      value: "nenhum",
      label: "Sem parceiro",
      filter: {
        originatorId: null,
      },
    },
    {
      value: "definido",
      label: "Com parceiro",
      filter: {
        originatorId: "anyOriginator",
      },
    },
  ],
};

const documentProviderFilter: TFilter = {
  label: "Seguradora",
  value: [],
  urlParam: "seguradora",
  name: "provider",
  options: Object.values(PropostaProviders).map((provider) => ({
    value: provider,
    label: provider.toUpperCase(),
    filter: {
      provider: provider,
    },
  })),
};

const documentCultureFilter: TFilter = {
  label: "Cultura",
  value: [],
  urlParam: "cultura",
  name: "culture",
  options: [],
  many2Many: {
    serviceName: Services.Cultures,
    domain: {},
  },
  toFilter: genericToFilter("cultureId"),
};

export const insuranceTypeGroupFilter: TFilter = {
  label: "Tipo de Seguro",
  value: [],
  urlParam: "seguro",
  name: "insurance",
  options: [
    {
      value: "agricola",
      label: "Agrícola",
      iconName: IconNames.Agriculture,
      filter: {
        insuranceTypeGroup: InsuranceTypeGroupEnum.Agricultural,
      },
    },
    {
      value: "maquinas",
      label: "Máquinas",
      iconName: IconNames.Machine,
      filter: {
        insuranceTypeGroup: InsuranceTypeGroupEnum.Machinery,
      },
    },
    {
      value: "floresta",
      label: "Floresta",
      iconName: IconNames.Forest,
      filter: {
        insuranceTypeGroup: InsuranceTypeGroupEnum.Forest,
      },
    },
    {
      value: "benfeitorias",
      label: "Benfeitorias",
      iconName: IconNames.Benefectory,
      filter: {
        insuranceTypeGroup: InsuranceTypeGroupEnum.PropertyImprovement,
      },
    },
    {
      value: "pecuaria",
      label: "Pecuária",
      iconName: IconNames.Pecuary,
      filter: {
        insuranceTypeGroup: InsuranceTypeGroupEnum.Livestock,
      },
    },
  ],
};

export const claimStatusFilter: TFilter<ClaimStatus | "noClaim" | "withClaim"> =
  {
    label: "Sinistros",
    value: [],
    urlParam: "sinistro",
    name: "claimStatus",
    selectOptionDisplay: "icon",
    selectMenuListDisplay: "buttons",
    selectMultiValueDisplay: "all",
    options: [
      {
        name: "noClaim",
        value: "sem-sinistro",
        label: "Sem Sinistro",
        filter: {
          claimStatus: "noClaim",
        },
        iconName: IconNames.Close,
      },
      {
        name: "withClaim",
        value: "com-sinistro",
        label: "Com Sinistro",
        filter: {
          claimStatus: "withClaim",
        },
        iconName: IconNames.Pending,
      },
      {
        name: ClaimStatus.Pending,
        value: "pendente",
        label: "Pendentes",
        filter: {
          claimStatus: ClaimStatus.Pending,
        },
        iconName: IconNames.Pending,
      },
      {
        name: ClaimStatus.Open,
        value: "aberto",
        label: "Abertos",
        filter: {
          claimStatus: ClaimStatus.Open,
        },
        iconName: IconNames.Claim,
      },
      {
        name: ClaimStatus.Approved,
        value: "deferido",
        label: "Deferidos",
        filter: {
          claimStatus: ClaimStatus.Approved,
        },
        iconName: IconNames.Done,
      },
      {
        name: ClaimStatus.Rejected,
        value: "indeferido",
        label: "Indeferidos",
        filter: {
          claimStatus: ClaimStatus.Rejected,
        },
        iconName: IconNames.Rejected,
      },
      {
        name: ClaimStatus.Contested,
        value: "em-contestacao",
        label: "Em Contestação",
        filter: {
          claimStatus: ClaimStatus.Contested,
        },
        iconName: IconNames.Close,
      },
      {
        name: ClaimStatus.Paid,
        value: "pago",
        label: "Pagos",
        filter: {
          claimStatus: ClaimStatus.Paid,
        },
        iconName: IconNames.Valid,
      },
    ],
  };

const createdAtFilter: TFilter = {
  label: "Data de criação",
  value: [],
  urlParam: "criacao",
  name: "createdAt",
  options: [],
  dateDisplay: DateDisplays.onlyDate,
};

const updatedAtFilter: TFilter = {
  label: "Data de atualização",
  value: [],
  urlParam: "atualizacao",
  name: "updatedAt",
  options: [],
  dateDisplay: DateDisplays.onlyDate,
};

const policyEndFilter: TFilter = {
  label: "Fim da vigência da apólice",
  value: [],
  urlParam: "fim-apolice",
  name: "policyEnd",
  options: [],
  dateDisplay: DateDisplays.onlyDate,
};

const harvestFilter: TFilter = {
  label: "Safra",
  value: [],
  urlParam: "safra",
  name: "harvestId",
  options: [],
  many2Many: {
    serviceName: Services.Harvests,
    domain: {},
  },
  toFilter: genericToFilter("harvestId"),
};
