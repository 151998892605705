/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import Avatar from "../../Avatar";
import { IconSizes } from "../../Icons/styles/iconSizes";
import { ColorNames } from "../../../theme";
import UserMenuDropdown from "./UserMenuDropdown";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";

const getCSSRules: CSSRulesResolver = () => ({
  userMenu: {
    position: "relative",
  },
});

const UserMenu: React.FC = () => {
  const { user } = useAuth();
  const styles = useCSSRulesWithTheme(getCSSRules);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleClickDropdown = () => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  return (
    <div>
      {user && (
        <div css={styles.userMenu}>
          <Avatar
            name={user?.name}
            size={IconSizes.ExtraLarge}
            color={ColorNames.GREEN}
            filled
            onClick={handleClickDropdown}
          />
          <UserMenuDropdown
            open={isMenuOpen}
            setOpen={(open) => setIsMenuOpen(open)}
          />
        </div>
      )}
    </div>
  );
};

export default UserMenu;
