import { useEffect } from "react";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import { useCreateFormResponseV2Mutation } from "../../../graphql/generated/types";
import { useAuth } from "../../../hooks/useAuth";
import FormV2 from "../../../modules/Forms/form.v2";
import {
  NewContactLocation,
  ToContactDetailsLocation,
} from "../../../Locations";
import { toast } from "react-toastify";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";
import FormTitle from "../../../modules/Forms/components/FormTitle";
import { useNavigate } from "react-router-dom";
import { ContactsWrapper } from "../ContactsWrapper";
import ScrollContainer from "../../../components/ScrollContainer";
import { IconNames } from "../../../components/Icons/styles/iconNames";

const formId = "contact";

export const NewContact = () => {
  const { errorHandler } = useErrorHandler();

  const [createFormResponseMutation, result] =
    useCreateFormResponseV2Mutation();
  const { loaded: authIsLoaded } = useAuth();
  const navigate = useNavigate();

  const resId = result.data?.createFormResponseV2;

  useEffect(() => {
    if (authIsLoaded && !resId) {
      createFormResponseMutation({
        variables: {
          formId,
          metadata: {},
        },
      }).catch((e) => errorHandler(new Error("Erro ao criar formulário"), e));
    }
  }, [authIsLoaded]);

  const handleSubmit = ({ contactId }: { contactId: string }) => {
    toast.success("Contato criado com sucesso");
    navigate(ToContactDetailsLocation(contactId));
  };

  if (!resId) return null;

  return (
    <ContactsWrapper
      submenus={[
        { title: "Novo", location: NewContactLocation, icon: IconNames.Add },
      ]}
    >
      <ScrollContainer>
        <Row>
          <Column lg={2} />
          <Column lg={8}>
            <FormTitle title="Novo contato" />
            <FormV2
              formId={formId}
              onSubmit={handleSubmit}
              formResponseId={resId}
            />
          </Column>
        </Row>
      </ScrollContainer>
    </ContactsWrapper>
  );
};
