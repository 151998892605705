/** @jsxImportSource @emotion/react */

import AuthLayout from "../../components/AuthLayout";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { useEffect, useState } from "react";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { useCreateFormResponseV2Mutation } from "../../graphql/generated/types";
import Typography from "../../components/Typography";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { LoginLocation } from "../../Locations";
import { FormSuccess } from "./components/FormSuccess";
import { tratorArando } from "../../s3/bgImages";
import FormV2 from "../../modules/Forms/form.v2";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  bgImage: {
    backgroundImage: `url(${tratorArando})`,
    backgroundSize: "cover",
    minHeight: "100%",
    width: "100%",
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      minHeight: 250,
    },
  },
  authContainer: {
    padding: "30px 50px",
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      padding: "30px 15px",
    },
  },
});

const Register = () => {
  const { errorHandler } = useErrorHandler();
  const styles = useCSSRulesWithTheme(getCSSRules);

  const [resId, setResId] = useState<string>("");
  const [showMessage, setShowMessage] = useState(false);
  const [createFormResponseMutation] = useCreateFormResponseV2Mutation();
  const { loaded: authIsLoaded } = useAuth();

  useEffect(() => {
    if (authIsLoaded && !resId) {
      createFormResponseMutation({
        variables: {
          formId: "register",
          metadata: {},
        },
      })
        .then(({ data }) => setResId(data!.createFormResponseV2))
        .catch((e) => errorHandler(new Error("Erro ao criar formulário"), e));
    }
  }, [authIsLoaded]);

  const handleSubmit = (id: string) => {
    if (id) {
      setShowMessage(true);
    }
  };

  return (
    <AuthLayout rightSideContent={<div css={styles.bgImage} />}>
      <div css={styles.authContainer}>
        <Typography variant="textMedium" component="div">
          Já tem uma conta? <Link to={LoginLocation}>Faça login</Link>
        </Typography>
        {showMessage && (
          <FormSuccess successMessage="Obrigado! Estamos analisando seu cadastro." />
        )}
        {!showMessage && resId && (
          <FormV2
            formId="register"
            onSubmit={handleSubmit}
            formResponseId={resId}
          />
        )}
      </div>
    </AuthLayout>
  );
};

export default Register;
