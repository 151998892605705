/** @jsxImportSource @emotion/react */
import { FC, useMemo } from "react";
import Column from "../Grid/Column";
import Typography, { FontWeight, TextAlign } from "../Typography";
import { formatISODate } from "../../utils/dateUtils";
import { ColorNames } from "../../theme";
import { CSSObject } from "@emotion/react";
import useColor from "../../hooks/useColor";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import { formatPrice } from "../../utils/formatNumber";

export interface TableCellOptions {
  special?:
    | "monetary"
    | "date"
    | "boolean"
    | "many2one"
    | "many2many"
    | "multiSelect"
    | "selectionOption";
  options?: Record<string, string>;
  textAlign?: TextAlign;
  fontWeight?: FontWeight;
}

type SelectionOption = { label?: string };

interface TableCellProps extends TableCellOptions {
  value:
    | string
    | number
    | { name?: string; displayName?: string }
    | string[]
    | SelectionOption;
  borderColor?: ColorNames;
}

const getStyles = ({ borderColor }: { borderColor?: string }): CSSObject => ({
  cell: {
    padding: "0.5em 0",
    "&:not(:first-of-type)": {
      borderLeft: borderColor ? `1px solid ${borderColor}` : "none",
    },
  },
});

const TableCell: FC<TableCellProps> = ({
  special,
  options,
  value,
  textAlign,
  fontWeight,
  borderColor: borderColorName,
}) => {
  const displayValue = useMemo(() => {
    if (options) return options[value as string];
    if (!special) return value;
    if (special === "monetary") return formatPrice(value as number);
    if (special === "boolean") return value ? "Sim" : "Não";
    if (special === "many2one")
      return (
        (value as { displayName: string })?.displayName ||
        (value as { name: string })?.name
      );
    if (special === "many2many")
      return (value as { name: string }[])?.map((item) => item.name).join(", ");
    if (special === "date") return formatISODate(value as string);
    if (special === "selectionOption") return (value as SelectionOption).label;
    if (special === "multiSelect") return (value as string[]).join(", ");
  }, [special, options, value]);

  const borderColor = useColor(borderColorName);
  const styles = useCSSRulesWithTheme(getStyles, { borderColor });

  return (
    <Column css={styles.cell}>
      <Typography
        align={textAlign}
        weight={fontWeight}
        variant="textMedium"
        component="div"
      >
        {displayValue as string}
      </Typography>
    </Column>
  );
};

export default TableCell;
