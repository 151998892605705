/** @jsxImportSource @emotion/react */

import React from "react";
import Typography, { BaseFontSize } from "../../../Typography";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";
import ChipSelectOption from "./components/ChipSelectOption";
import Icon from "../../../Icons";
import { IconNames } from "../../../Icons/styles/iconNames";

interface ChipSelectInputProps {
  title?: string;
  values: string[];
  options: {
    value: string;
    label: string;
    iconName?: IconNames;
  }[];
  onChange: (value: string[]) => void;
  containerId?: string;
  isSingleOption?: boolean;
  error?: string;
  disabled?: boolean;
}

const getStyles: CSSRulesResolver = () => ({
  container: {
    width: "100%",
  },
  disabledContainer: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  chips: {
    marginTop: 12,
    display: "flex",
    flexWrap: "wrap",
    gap: 8,
  },
  label: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
});

const ChipSelectInput: React.FC<ChipSelectInputProps> = ({
  title,
  options,
  values,
  onChange,
  containerId,
  isSingleOption,
  error,
  disabled,
}) => {
  const styles = useCSSRulesWithTheme(getStyles);

  const onChipClick = (option: string) => {
    if (disabled) return;
    if (isSingleOption) {
      onChange([option]);
      return;
    }
    const result = values.includes(option)
      ? values.filter((v) => v !== option)
      : [...values, option];
    onChange(result);
  };

  return (
    <div
      css={[styles.container, disabled && styles.disabledContainer]}
      id={containerId}
    >
      {!!title && (
        <Typography
          width="100%"
          variant="h3"
          margin="none"
          size={22}
          align="left"
          weight="bold"
        >
          {title}
        </Typography>
      )}
      <div css={styles.chips}>
        {options.map(({ value, label, iconName }) => (
          <ChipSelectOption
            key={`ChipSelectOption-${label}`}
            selected={values.includes(value)}
            onClick={() => onChipClick(value)}
            disabled={disabled}
          >
            <div css={styles.label}>
              {iconName && <Icon pointer name={iconName} size={BaseFontSize} />}
              {label}
            </div>
          </ChipSelectOption>
        ))}
      </div>
      {error && (
        <Typography
          variant="textSmall"
          color="ErrorRed"
          align="left"
          component="div"
          css={{ width: "100%", paddingTop: "8px" }}
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export default ChipSelectInput;
