/** @jsxImportSource @emotion/react */
import { FC, useState, useEffect, ReactNode } from "react";
import useDebounce from "../../../hooks/useDebounce";
import { theme, ColorNames } from "../../../theme";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";
import { BaseFontSize } from "../../Typography";

const styles = {
  searchBar: {
    width: "100%",
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "all 0.2s ease-in-out",
    boxSizing: "border-box" as "border-box",
  },
  searchInput: {
    cursor: "text",
    width: "100%",
    border: "none",
    fontFamily: "inherit",
    fontSize: 16,
    backgroundColor: "transparent",
    paddingLeft: 4,
    "&:focus-visible": {
      outline: "none",
    },
    "&::placeholder": {
      color: `${theme.colors.Grey[100]}`,
    },
  },
};

interface BasicSearchProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  children?: ReactNode;
}

const BasicSearch: FC<BasicSearchProps> = ({
  searchTerm,
  setSearchTerm,
  children,
}) => {
  const [search, setSearch] = useState(searchTerm);
  const { debouncedValue: debouncedSearch } = useDebounce(search, 1000);

  useEffect(() => {
    setSearchTerm(debouncedSearch);
  }, [debouncedSearch]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <div css={styles.searchBar}>
      <Icon
        name={IconNames.Search}
        color={ColorNames.GREY}
        size={1.25 * BaseFontSize}
      />
      <input
        type="text"
        placeholder="Buscar..."
        css={styles.searchInput}
        onChange={handleSearch}
        value={search}
      />
      {children}
    </div>
  );
};

export default BasicSearch;
