/** @jsxImportSource @emotion/react */

import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  SubMenu,
} from "@szhsin/react-menu";
import React from "react";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";

export interface NestedMenuOption {
  label: string | React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
  keepOpen?: boolean;
  items?: NestedMenuOption[];
}

interface NestedMenuProps {
  menuIcon?: React.ReactNode;
  options: NestedMenuOption[];
  header?: React.ReactNode;
  id?: string;
}

const getCSSRules: CSSRulesResolver = (props) => ({
  menu: {
    fontWeight: "normal",
    zIndex: 20000,
    maxHeight: 100,

    "> *": {
      padding: 0,
      maxHeight: 500,
      minWidth: 200,
    },
  },
  menuButton: {
    cursor: "pointer",
    padding: 0,
    backgroundColor: "transparent",
    height: 42,
    width: 42,
  },
  menuItem: {
    padding: 0,
    overflow: "hidden",
  },
  subMenu: {
    padding: 0,
    "> *": {
      padding: 12,
    },
    ul: {
      padding: 0,
      borderRadius: 4,
      maxHeight: 300,
      overflowY: "auto",
    },
  },
  count: {
    padding: "6px 12px",
    textAlign: "left",
    borderBottom: `1px solid ${props.colors.Grey[40]}`,
    color: props.colors.Black[80],
  },
  label: {
    padding: 12,
    overflow: "hidden",
  },
  selected: {
    backgroundColor: props.colors.Green[20],
  },
  divider: {
    margin: 0,
  },
});

const defaultMenuIconStyles: CSSRulesResolver = (props) => ({
  container: {
    backgroundColor: props.colors.Grey[20],
    height: 42,
    width: 42,
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const DefaultMenuIcon: React.FC = () => {
  const styles = useCSSRulesWithTheme(defaultMenuIconStyles);
  return (
    <div css={styles.container}>
      <Icon name={IconNames.OverflowMenuVertical} pointer />
    </div>
  );
};

const NestedMenu: React.FC<NestedMenuProps> = ({
  options,
  menuIcon,
  header,
  id,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  const renderItems = (actions: NestedMenuOption[]) => {
    return actions.map(({ items, label, onClick, selected, keepOpen }) => {
      if (items) {
        return (
          <SubMenu key={`SubMenu-${label}`} css={styles.subMenu} label={label}>
            {renderItems(items)}
          </SubMenu>
        );
      } else {
        return (
          <MenuItem
            key={`MenuItem-${label}`}
            css={[styles.menuItem, selected && styles.selected]}
            onClick={(e) => {
              e.keepOpen = keepOpen;
              if (onClick) onClick();
            }}
          >
            {typeof label === "string" ? (
              <div css={styles.label}>{label}</div>
            ) : (
              label
            )}
          </MenuItem>
        );
      }
    });
  };

  return (
    <Menu
      css={styles.menu}
      menuButton={
        <MenuButton id={id} css={styles.menuButton}>
          {menuIcon || <DefaultMenuIcon />}
        </MenuButton>
      }
      direction="bottom"
      align="end"
      position="anchor"
      arrow={true}
      shift={0}
    >
      {header}
      {header && <MenuDivider css={styles.divider} />}
      {renderItems(options)}
    </Menu>
  );
};

export default NestedMenu;
