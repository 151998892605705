import Table from "../../../../components/Table";
import {
  Payment,
  SubventionType,
  useListPaymentsQuery,
} from "../../../../graphql/generated/types";
import useContactDetails from "../../../../hooks/useContactDetails";
import { ColorNames } from "../../../../theme";
import { ToPaymentDetailsLocation } from "../../../../Locations";
import { formatPrice } from "../../../../utils/formatNumber";
import { formatISODate } from "../../../../utils/dateUtils";
import { PaymentStatusLabels } from "../../../Documents/DocumentDetails/sections/Payments/PaymentList";
import { getSubventionTotalsThisYear } from "../../utils/payments";
import DetailsSectionColumns from "../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { useMemo } from "react";
import DetailsSection from "../../../../components/Details/components/Main/Section/DetailsSection";
import DetailsSectionTitle from "../../../../components/Details/components/Main/Section/DetailsSectionTitle";

type TablePaymentsData = {
  _id: string;
  name: string;
  amount: string;
  documentId: string;
  dateDue: string;
  status: string;
};

const ContactPayments = () => {
  const { contactData } = useContactDetails();

  const { data } = useListPaymentsQuery({
    variables: {
      filter: [[{ responsibleId: contactData._id! }]],
    },
  });

  const tablePaymentsData: TablePaymentsData[] = (data?.payments || []).map(
    (payment) => ({
      ...payment,
      amount: formatPrice(payment.amount),
      dateDue: formatISODate(payment.dateDue),
      status: PaymentStatusLabels[payment.status],
      documentId: payment.document?._id!,
    })
  );

  const subventionTotalsThisYear = useMemo(
    () => getSubventionTotalsThisYear((data?.payments as Payment[]) || []),
    [data?.payments]
  );

  return (
    <DetailsSection
      title={`Totais de subvenções usadas em ${new Date().getFullYear()}`}
      subtitle
    >
      <DetailsSectionColumns
        columns={[
          [
            {
              label: "Subvenção federal",
              value: formatPrice(
                subventionTotalsThisYear[SubventionType.Federal]
              ),
            },
          ],
          [
            {
              label: "Subvenção estadual",
              value: formatPrice(
                subventionTotalsThisYear[SubventionType.State]
              ),
            },
          ],
        ]}
      />
      <DetailsSectionTitle title="Parcelas" subtitle showSectionContent />

      <Table<TablePaymentsData>
        data={tablePaymentsData}
        withBorder
        color={ColorNames.BLUE}
        rowLink={(payment: TablePaymentsData) =>
          ToPaymentDetailsLocation(payment._id)
        }
        columns={[
          {
            label: "Parcela",
            key: "name",
          },
          {
            label: "Vencimento",
            key: "dateDue",
          },
          {
            label: "Valor",
            key: "amount",
          },
          {
            label: "Status",
            key: "status",
          },
        ]}
      />
    </DetailsSection>
  );
};

export default ContactPayments;
