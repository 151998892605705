/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import {
  ImportDocumentInput,
  useImportDocumentMutation,
} from "../../../../../graphql/generated/types";
import Typography from "../../../../../components/Typography";
import UploadInput from "../../../../../components/Input/UploadInput";
import papa from "papaparse";
import Button from "../../../../../components/Button";
import ProgressItem, {
  ProgressItemProps,
  ProgressStatus,
} from "./components/ProgressItem";
import { z } from "zod";
import { theme } from "../../../../../theme";
import { useModalStore } from "../../../../../components/Modal/context/ModalStore";

interface UpdaloadDocumentModalProps {
  refetch: () => void;
}

const defaultError = (key: string) => `Campo '${key}' é obrigatório`;

const DocumentInput = z
  .object<Record<keyof ImportDocumentInput, z.ZodTypeAny>>({
    nome: z
      .string({
        message: defaultError("nome"),
      })
      .min(1, defaultError("nome")),
    email: z.string({ message: defaultError("email") }).email("Email inválido"),
    cpf: z.string({ message: defaultError("cpf") }).min(1, defaultError("cpf")),
    cep: z.string({ message: defaultError("cep") }).min(1, defaultError("cep")),
    telefone: z
      .string({ message: defaultError("telefone") })
      .regex(new RegExp(/^[1-9][0-9]{9,10}$/), "Telefone inválido"),
    cultura: z
      .string({ message: defaultError("cultura") })
      .min(1, defaultError("cultura")),
    detalhe: z.string({ message: defaultError("detalhe") }),
    area: z
      .string({ message: defaultError("area") })
      .min(1, defaultError("area")),
    anoCultura: z
      .string({ message: defaultError("anoCultura") })
      .min(1, defaultError("anoCultura")),
    parceiro: z.string({ message: defaultError("parceiro") }),
    dataPlantio: z
      .string({ message: defaultError("parceiro") })
      .regex(/[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}/, "dataPlantio: Data inválida"),
  })
  .strip();

const getStyles = () => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    > * {
      margin: 0 !important;
    }

    h2 {
      text-align: left;
    }
  `,
  progress: css`
    background-color: ${theme.colors.LightGrey[100]};
    padding: 5px;
    height: 160px;
    overflow: auto;
  `,
  downloadTemplate: css`
    display: inline-block;
    width: 100%;
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
    color: ${theme.colors.Black[80]};
  `,
});

const UploadDocumentModal: React.FC<UpdaloadDocumentModalProps> = ({
  refetch,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState("");
  const [csvData, setCsvData] = useState<ImportDocumentInput[]>([]);
  const [progressItems, setProgressItems] = useState<ProgressItemProps[]>([]);
  const [isDone, setIsDone] = useState(false);
  const [importDocument] = useImportDocumentMutation();
  const styles = getStyles();
  const { closeModal } = useModalStore();

  const updateProgressState = (
    progress: ProgressItemProps[],
    item: ProgressItemProps,
    idx: number
  ) => {
    const newProgress = [...progress];
    newProgress.splice(idx, 1, { ...item });
    setProgressItems(newProgress);
    return newProgress;
  };

  const handleCloseModal = () => {
    refetch();
    closeModal();
  };

  const handleImportDocument = async () => {
    let progress: ProgressItemProps[] = [...progressItems];
    let idx = progress.length;
    for (let i = 0; i < csvData.length; i++) {
      const item: ProgressItemProps = {
        status: ProgressStatus.LOADING,
        label: `Linha ${i + 1}`,
      };
      progress = updateProgressState(progress, item, i + idx);
      const document = csvData[i];
      try {
        const result = await importDocument({
          variables: {
            document: document,
          },
        });
        if (result.data?.importDocument) {
          item.status = ProgressStatus.DONE;
        } else {
          item.status = ProgressStatus.ERROR;
          item.error = result.errors?.map
            ? result.errors.map((error) => error.message).join("\n")
            : String(result.errors) || "";
        }
        progress = updateProgressState(progress, item, i + idx);
      } catch (e) {
        item.status = ProgressStatus.ERROR;
        item.error = (e as Error).message;
        progress = updateProgressState(progress, item, i + idx);
      }
      setIsDone(true);
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    let progress: ProgressItemProps[] = [];
    if (e.target.files) {
      setProgressItems([]);
      const inputFile = e.target.files[0];
      setFile(inputFile);
      setFileName(inputFile.name);
      const validationProgress = {
        label: "Validando planílha...",
        status: ProgressStatus.LOADING,
      };
      progress = updateProgressState(progress, validationProgress, 0);
      papa.parse<ImportDocumentInput>(inputFile, {
        header: true,
        skipEmptyLines: true,
        complete: async ({ data }) => {
          let hasError = false;
          const parsedData = data.map((line, i) => {
            const result = DocumentInput.safeParse(line);
            if (!result.success) {
              hasError = true;
              progress = updateProgressState(
                progress,
                {
                  label: `Linha ${i + 1}`,
                  status: ProgressStatus.ERROR,
                  error: result.error.issues
                    .map(({ message }) => message)
                    .join(", "),
                },
                progress.length + 1
              );
            }

            return result.data!;
          });

          if (hasError) {
            validationProgress.status = ProgressStatus.ERROR;
          } else {
            validationProgress.status = ProgressStatus.DONE;
            setCsvData(parsedData as ImportDocumentInput[]);
          }

          updateProgressState(progress, validationProgress, 0);
        },
      });
    }
  };

  const handleFileDelete = () => {
    setFile(null);
    setFileName("");
    setCsvData([]);
  };

  return (
    <div css={styles.container}>
      <Typography variant="h2">Importar Planílha</Typography>
      <div>
        <UploadInput
          id="uploadCSV"
          label="Escolher Arquivo"
          fileType="csv"
          file={file}
          fileName={fileName}
          onDelete={handleFileDelete}
          handleChange={handleFileUpload}
          customStyles={{ marginBottom: 5, marginTop: 0 }}
        />
        <a
          css={styles.downloadTemplate}
          href="https://prisma-agro-assets.s3.us-east-1.amazonaws.com/modelo_upload_cotacao.csv"
        >
          Baixar modelo de planílha
        </a>
      </div>
      <div css={styles.progress}>
        {progressItems.map((itemProps) => (
          <ProgressItem key={itemProps.label} {...itemProps} />
        ))}
      </div>
      <Button
        text={isDone ? "Finalizar" : `Importar ${csvData.length} linhas`}
        onClick={isDone ? handleCloseModal : handleImportDocument}
        disabled={csvData.length === 0}
      />
    </div>
  );
};

export default UploadDocumentModal;
