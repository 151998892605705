/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { Contact } from "../../../graphql/generated/types";
import DetailsSectionColumns from "../components/Main/Grid/DetailsSectionColumns";
import {
  formatCep,
  formatCpfCnpj,
  formatInputMask,
  formatPhone,
} from "../../../utils/formatNumber";
import { formatISODate } from "../../../utils/dateUtils";
import { CivilStateLabels } from "../labels";
import CustomLink from "../../CustomLink";
import { ToContactDetailsLocation } from "../../../Locations";
import Typography from "../../Typography";

interface ContactDataProps {
  contact: Contact;
}

const ContactData: FC<ContactDataProps> = ({
  contact: {
    name,
    cpfCnpj,
    email,
    mobilePhone,
    rg,
    emissionInstitution,
    expeditionDate,
    civilState,
    gender,
    profession,
    birthDate,
    address,
    secondaryPhone,
    secondaryEmail,
    agronomistResponsible,
  },
}) => {
  const personalInfoLeftColumn = useMemo(
    () => [
      {
        label: "Nome",
        value: name,
      },
      {
        label: "CPF/CNPJ",
        value: formatCpfCnpj(cpfCnpj!),
      },
      {
        label: "E-mail",
        value: email,
      },
      {
        label: "E-mail secundário",
        value: secondaryEmail,
      },
      {
        label: "Telefone",
        value: formatPhone(mobilePhone!),
      },
      {
        label: "Telefone secundário",
        value: formatPhone(secondaryPhone!),
      },
    ],
    [name, cpfCnpj, mobilePhone, email, secondaryPhone, secondaryEmail]
  );

  const personalInfoRightColumn = useMemo(
    () => [
      {
        label: "RG",
        value: rg && formatInputMask(rg!, "rg"),
      },
      {
        label: "Órgão emissor",
        value: emissionInstitution || "",
      },
      {
        label: "Data de expedição",
        value: formatISODate(expeditionDate) || "",
      },
      {
        label: "Estado civil/ gênero",
        value: `${civilState ? CivilStateLabels[civilState] : " - "} / ${
          gender ? gender : " - "
        }`,
      },
      {
        label: "Data de nascimento",
        value: formatISODate(birthDate),
      },
      {
        label: "Profissão",
        value: profession,
      },
    ],
    [
      rg,
      expeditionDate,
      emissionInstitution,
      civilState,
      gender,
      birthDate,
      profession,
    ]
  );

  const addressInfo = useMemo(
    () => [
      {
        label: "CEP",
        value: formatCep(address?.cep!),
      },
      {
        label: "Cidade/Estado",
        value: `${address?.city || " - "} / ${address?.state || " - "}`,
      },
      {
        label: "Bairro",
        value: address?.neighborhood,
      },
      {
        label: "Endereço",
        value: `${address?.street || " - "}${
          address?.number ? ", " + address?.number : ""
        }`,
      },
      {
        label: "Complemento",
        value: address?.complement,
      },
    ],
    [
      address?.cep,
      address?.city,
      address?.state,
      address?.neighborhood,
      address?.street,
      address?.number,
      address?.complement,
    ]
  );

  return (
    <>
      <DetailsSectionColumns
        columns={[personalInfoLeftColumn, personalInfoRightColumn]}
      />
      <DetailsSectionColumns
        columns={[
          addressInfo,
          [
            {
              label: "Agrônomo responsável",
              value: agronomistResponsible ? (
                <CustomLink
                  to={ToContactDetailsLocation(agronomistResponsible?._id!)}
                >
                  <Typography variant="link">
                    {agronomistResponsible?.name}
                  </Typography>
                </CustomLink>
              ) : null,
            },
          ],
        ]}
      />
    </>
  );
};

export default ContactData;
