/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { useParams } from "react-router-dom";

import { useGetContactQuery } from "../../../graphql/generated/types";
import SkeletonDetails from "../../../components/Details/components/SkeletonDetails";
import ContactDetailsProvider from "../../../contexts/contacts";
import { ContactDetailsRoutes } from "../Routes";
import { ContactsWrapper } from "../ContactsWrapper";

const ContactDetails: FC = () => {
  const params = useParams();
  const contactId = params.contactId!;
  const { data, loading, refetch } = useGetContactQuery({
    variables: { contactId },
  });

  if (loading) return <SkeletonDetails />;

  return (
    <ContactDetailsProvider contactData={data!.contact} refetch={refetch}>
      <ContactsWrapper selectedContact={data?.contact}>
        <ContactDetailsRoutes />
      </ContactsWrapper>
    </ContactDetailsProvider>
  );
};

export default ContactDetails;
