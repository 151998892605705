import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import useDebouncedMaskedValue from "./useMaskedDebouncedValue";
import DateInput from "../../../../components/Input/DateInput";
import FormLabel from "../FormLabel";
import VariableFieldColumn from "../utils/VariableFieldColumn";

interface DateFieldProps {
  field: FormFieldV2;
}

const DateField: FC<DateFieldProps> = ({ field }) => {
  const { name, label, value, disabled, variant } = field;

  const { handleChange, editing, startEditing, finishEditing, error } =
    useFormField(field);

  const { localValue, handleInputChange, forceDebounce } =
    useDebouncedMaskedValue(value, editing, handleChange);

  return (
    <VariableFieldColumn variant={variant}>
      <FormLabel>{label}</FormLabel>
      <DateInput
        placeholder={label.toLowerCase()}
        disabled={!!disabled}
        name={name}
        value={localValue}
        onChange={handleInputChange}
        onFocus={startEditing}
        onBlur={() => {
          forceDebounce();
          finishEditing();
        }}
        errorMessage={error}
      />
    </VariableFieldColumn>
  );
};

export default DateField;
