import { FC } from "react";
import { CultureProvider } from "../context/culture";
import { useCultureQuery } from "../../../graphql/generated/types";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import { CulturesWrapper } from "../Wrapper";
import PageTitle from "../../../components/PageTitle";
import { CultureDetailsRoutes } from "../Routes";

const Culture: FC = () => {
  const { cultureId } = useParams();
  const { data, loading, refetch } = useCultureQuery({
    variables: {
      cultureId: cultureId!,
    },
  });
  if (loading || !data) return <Loading size="extraLarge" />;
  return (
    <CultureProvider culture={data!.culture!} refetch={refetch}>
      <CulturesWrapper selectedCultureId={cultureId}>
        <PageTitle title={"Cultura - " + data.culture?.name} />
        <CultureDetailsRoutes />
      </CulturesWrapper>
    </CultureProvider>
  );
};

export default Culture;
