/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import ActionButtons from "./components/ActionButtons";
import { ColorNames, theme } from "../../theme";
import Button from "../Button";
import { useFlags } from "../../hooks/useFlags";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { IconSizes } from "../Icons/styles/iconSizes";
import ScrollContainer from "../ScrollContainer";
import CustomLink from "../CustomLink";

export interface MenuItem {
  title: string;
  icon?: IconNames;
  location?: string;
  disabled?: boolean;
  flags?: string[];
  submenus?: MenuItem[];
  onClick?: () => void;
  newTab?: boolean;
}

interface LeftMenuProps {
  menuItems: MenuItem[];
}

interface StyleParams {
  collapseVisible: boolean;
  collapsed: boolean;
}

const getCSSRules: CSSRulesResolver<StyleParams> = ({
  collapseVisible,
  collapsed,
}) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: collapsed ? 66 : 240,
    transition: "width 0.1s ease",
  },
  logo: {
    marginTop: 12,
    height: 66,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    a: {
      display: "inline-block",
    },
    img: {
      maxHeight: 42,
      maxWidth: "100%",
    },
  },
  separator: {
    position: "relative",
    height: 1,
    width: "100%",
    backgroundColor: theme.colors.Grey[20],
    margin: "24px 0",
  },
  collapse: {
    position: "absolute",
    top: -13,
    right: -13,
    height: 26,
    width: 26,
    backgroundColor: theme.colors.White[100],
    borderRadius: "50%",
    cursor: "pointer !important",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
    border: `1px solid ${theme.colors.Grey[20]}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: collapseVisible ? 1 : 0,
    "> span": {
      cursor: "pointer !important",
    },
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    padding: "0 12px",
    boxSizing: "border-box",
  },

  submenu: {
    borderLeft: `1px solid ${theme.colors.Grey[40]}`,
    display: "flex",
    flexDirection: "column",
    gap: 12,
    marginLeft: 6,
    paddingLeft: 6,
    marginTop: 4,
  },
});

const LeftMenu: React.FC<LeftMenuProps> = ({ menuItems }) => {
  const { isFlagEnabled } = useFlags();
  const [collapseVisible, setCollapseVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const styles = useCSSRulesWithTheme(getCSSRules, {
    collapseVisible,
    collapsed,
  });

  const onMenuMouseEnter = () => setCollapseVisible(true);
  const onMenuMouseLeave = () => setCollapseVisible(false);
  const toggleCollapse = () => setCollapsed(!collapsed);

  const renderButton = (item: MenuItem) => {
    const button = (
      <Button
        style="link"
        text={collapsed ? "" : item.title}
        align="left"
        selected={
          item.location === location.pathname || !!item.submenus?.length
        }
        onClick={item.onClick}
        disabled={item.disabled}
        icon={item.icon ? <Icon name={item.icon} /> : undefined}
        iconBefore
      />
    );
    if (item.disabled || !item.location) {
      return button;
    } else {
      return (
        <CustomLink
          to={item.location}
          target={item.newTab ? "_blank" : undefined}
        >
          {button}
        </CustomLink>
      );
    }
  };

  return (
    <div
      css={styles.container}
      onMouseEnter={onMenuMouseEnter}
      onMouseLeave={onMenuMouseLeave}
    >
      <div css={styles.logo}>
        <CustomLink to="/">
          <img src={collapsed ? "/logo512.png" : "/logo-full.png"} alt="Logo" />
        </CustomLink>
      </div>

      <ActionButtons />

      <div css={styles.separator}>
        <div css={styles.collapse} onClick={toggleCollapse}>
          <Icon
            name={collapsed ? IconNames.ChevronRight : IconNames.ChevronLeft}
            color={ColorNames.GREEN}
            size={IconSizes.Medium}
          />
        </div>
      </div>

      <ScrollContainer
        css={{
          overflow: "auto !important",
        }}
        padding={0}
      >
        <div id="leftMenu" css={styles.menu}>
          {menuItems.map((item, index) => {
            // Respect feature flags, if specified
            if (
              item.flags &&
              !item.flags.every((flag) => isFlagEnabled(flag))
            ) {
              return null;
            }

            return (
              <React.Fragment key={`menuItem-${index}`}>
                {renderButton(item)}
                {/* Only show submenus if not collapsed and if submenus exist */}
                {!collapsed && item.submenus && item.submenus.length > 0 && (
                  <div id="leftMenu-subMenu" css={styles.submenu}>
                    {item.submenus.map((submenu, sIndex) => {
                      // Respect feature flags in submenus
                      if (
                        submenu.flags &&
                        !submenu.flags.every((flag) => isFlagEnabled(flag))
                      ) {
                        return null;
                      }
                      return <span key={sIndex}>{renderButton(submenu)}</span>;
                    })}
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </ScrollContainer>
    </div>
  );
};

export default LeftMenu;
